export default {
  "province_list": {
    "110000000000": "北京市",
    "120000000000": "天津市",
    "130000000000": "河北省",
    "140000000000": "山西省",
    "150000000000": "内蒙古自治区",
    "210000000000": "辽宁省",
    "220000000000": "吉林省",
    "230000000000": "黑龙江省",
    "310000000000": "上海市",
    "320000000000": "江苏省",
    "330000000000": "浙江省",
    "340000000000": "安徽省",
    "350000000000": "福建省",
    "360000000000": "江西省",
    "370000000000": "山东省",
    "410000000000": "河南省",
    "420000000000": "湖北省",
    "430000000000": "湖南省",
    "440000000000": "广东省",
    "450000000000": "广西壮族自治区",
    "460000000000": "海南省",
    "500000000000": "重庆市",
    "510000000000": "四川省",
    "520000000000": "贵州省",
    "530000000000": "云南省",
    "540000000000": "西藏自治区",
    "610000000000": "陕西省",
    "620000000000": "甘肃省",
    "630000000000": "青海省",
    "640000000000": "宁夏回族自治区",
    "650000000000": "新疆维吾尔自治区"
  },
  "city_list": {
    "110100000000": "市辖区",
    "120100000000": "市辖区",
    "130100000000": "石家庄市",
    "130200000000": "唐山市",
    "130300000000": "秦皇岛市",
    "130400000000": "邯郸市",
    "130500000000": "邢台市",
    "130600000000": "保定市",
    "130700000000": "张家口市",
    "130800000000": "承德市",
    "130900000000": "沧州市",
    "131000000000": "廊坊市",
    "131100000000": "衡水市",
    "140100000000": "太原市",
    "140200000000": "大同市",
    "140300000000": "阳泉市",
    "140400000000": "长治市",
    "140500000000": "晋城市",
    "140600000000": "朔州市",
    "140700000000": "晋中市",
    "140800000000": "运城市",
    "140900000000": "忻州市",
    "141000000000": "临汾市",
    "141100000000": "吕梁市",
    "150100000000": "呼和浩特市",
    "150200000000": "包头市",
    "150300000000": "乌海市",
    "150400000000": "赤峰市",
    "150500000000": "通辽市",
    "150600000000": "鄂尔多斯市",
    "150700000000": "呼伦贝尔市",
    "150800000000": "巴彦淖尔市",
    "150900000000": "乌兰察布市",
    "152200000000": "兴安盟",
    "152500000000": "锡林郭勒盟",
    "152900000000": "阿拉善盟",
    "210100000000": "沈阳市",
    "210200000000": "大连市",
    "210300000000": "鞍山市",
    "210400000000": "抚顺市",
    "210500000000": "本溪市",
    "210600000000": "丹东市",
    "210700000000": "锦州市",
    "210800000000": "营口市",
    "210900000000": "阜新市",
    "211000000000": "辽阳市",
    "211100000000": "盘锦市",
    "211200000000": "铁岭市",
    "211300000000": "朝阳市",
    "211400000000": "葫芦岛市",
    "220100000000": "长春市",
    "220200000000": "吉林市",
    "220300000000": "四平市",
    "220400000000": "辽源市",
    "220500000000": "通化市",
    "220600000000": "白山市",
    "220700000000": "松原市",
    "220800000000": "白城市",
    "222400000000": "延边朝鲜族自治州",
    "230100000000": "哈尔滨市",
    "230200000000": "齐齐哈尔市",
    "230300000000": "鸡西市",
    "230400000000": "鹤岗市",
    "230500000000": "双鸭山市",
    "230600000000": "大庆市",
    "230700000000": "伊春市",
    "230800000000": "佳木斯市",
    "230900000000": "七台河市",
    "231000000000": "牡丹江市",
    "231100000000": "黑河市",
    "231200000000": "绥化市",
    "232700000000": "大兴安岭地区",
    "310100000000": "市辖区",
    "320100000000": "南京市",
    "320200000000": "无锡市",
    "320300000000": "徐州市",
    "320400000000": "常州市",
    "320500000000": "苏州市",
    "320600000000": "南通市",
    "320700000000": "连云港市",
    "320800000000": "淮安市",
    "320900000000": "盐城市",
    "321000000000": "扬州市",
    "321100000000": "镇江市",
    "321200000000": "泰州市",
    "321300000000": "宿迁市",
    "330100000000": "杭州市",
    "330200000000": "宁波市",
    "330300000000": "温州市",
    "330400000000": "嘉兴市",
    "330500000000": "湖州市",
    "330600000000": "绍兴市",
    "330700000000": "金华市",
    "330800000000": "衢州市",
    "330900000000": "舟山市",
    "331000000000": "台州市",
    "331100000000": "丽水市",
    "340100000000": "合肥市",
    "340200000000": "芜湖市",
    "340300000000": "蚌埠市",
    "340400000000": "淮南市",
    "340500000000": "马鞍山市",
    "340600000000": "淮北市",
    "340700000000": "铜陵市",
    "340800000000": "安庆市",
    "341000000000": "黄山市",
    "341100000000": "滁州市",
    "341200000000": "阜阳市",
    "341300000000": "宿州市",
    "341500000000": "六安市",
    "341600000000": "亳州市",
    "341700000000": "池州市",
    "341800000000": "宣城市",
    "350100000000": "福州市",
    "350200000000": "厦门市",
    "350300000000": "莆田市",
    "350400000000": "三明市",
    "350500000000": "泉州市",
    "350600000000": "漳州市",
    "350700000000": "南平市",
    "350800000000": "龙岩市",
    "350900000000": "宁德市",
    "360100000000": "南昌市",
    "360200000000": "景德镇市",
    "360300000000": "萍乡市",
    "360400000000": "九江市",
    "360500000000": "新余市",
    "360600000000": "鹰潭市",
    "360700000000": "赣州市",
    "360800000000": "吉安市",
    "360900000000": "宜春市",
    "361000000000": "抚州市",
    "361100000000": "上饶市",
    "370100000000": "济南市",
    "370200000000": "青岛市",
    "370300000000": "淄博市",
    "370400000000": "枣庄市",
    "370500000000": "东营市",
    "370600000000": "烟台市",
    "370700000000": "潍坊市",
    "370800000000": "济宁市",
    "370900000000": "泰安市",
    "371000000000": "威海市",
    "371100000000": "日照市",
    "371300000000": "临沂市",
    "371400000000": "德州市",
    "371500000000": "聊城市",
    "371600000000": "滨州市",
    "371700000000": "菏泽市",
    "410100000000": "郑州市",
    "410200000000": "开封市",
    "410300000000": "洛阳市",
    "410400000000": "平顶山市",
    "410500000000": "安阳市",
    "410600000000": "鹤壁市",
    "410700000000": "新乡市",
    "410800000000": "焦作市",
    "410900000000": "濮阳市",
    "411000000000": "许昌市",
    "411100000000": "漯河市",
    "411200000000": "三门峡市",
    "411300000000": "南阳市",
    "411400000000": "商丘市",
    "411500000000": "信阳市",
    "411600000000": "周口市",
    "411700000000": "驻马店市",
    "419000000000": "省直辖县级行政区划",
    "420100000000": "武汉市",
    "420200000000": "黄石市",
    "420300000000": "十堰市",
    "420500000000": "宜昌市",
    "420600000000": "襄阳市",
    "420700000000": "鄂州市",
    "420800000000": "荆门市",
    "420900000000": "孝感市",
    "421000000000": "荆州市",
    "421100000000": "黄冈市",
    "421200000000": "咸宁市",
    "421300000000": "随州市",
    "422800000000": "恩施土家族苗族自治州",
    "429000000000": "省直辖县级行政区划",
    "430100000000": "长沙市",
    "430200000000": "株洲市",
    "430300000000": "湘潭市",
    "430400000000": "衡阳市",
    "430500000000": "邵阳市",
    "430600000000": "岳阳市",
    "430700000000": "常德市",
    "430800000000": "张家界市",
    "430900000000": "益阳市",
    "431000000000": "郴州市",
    "431100000000": "永州市",
    "431200000000": "怀化市",
    "431300000000": "娄底市",
    "433100000000": "湘西土家族苗族自治州",
    "440100000000": "广州市",
    "440200000000": "韶关市",
    "440300000000": "深圳市",
    "440400000000": "珠海市",
    "440500000000": "汕头市",
    "440600000000": "佛山市",
    "440700000000": "江门市",
    "440800000000": "湛江市",
    "440900000000": "茂名市",
    "441200000000": "肇庆市",
    "441300000000": "惠州市",
    "441400000000": "梅州市",
    "441500000000": "汕尾市",
    "441600000000": "河源市",
    "441700000000": "阳江市",
    "441800000000": "清远市",
    "441900000000": "东莞市",
    "442000000000": "中山市",
    "445100000000": "潮州市",
    "445200000000": "揭阳市",
    "445300000000": "云浮市",
    "450100000000": "南宁市",
    "450200000000": "柳州市",
    "450300000000": "桂林市",
    "450400000000": "梧州市",
    "450500000000": "北海市",
    "450600000000": "防城港市",
    "450700000000": "钦州市",
    "450800000000": "贵港市",
    "450900000000": "玉林市",
    "451000000000": "百色市",
    "451100000000": "贺州市",
    "451200000000": "河池市",
    "451300000000": "来宾市",
    "451400000000": "崇左市",
    "460100000000": "海口市",
    "460200000000": "三亚市",
    "460300000000": "三沙市",
    "460400000000": "儋州市",
    "469000000000": "省直辖县级行政区划",
    "500100000000": "市辖区",
    "500200000000": "县",
    "510100000000": "成都市",
    "510300000000": "自贡市",
    "510400000000": "攀枝花市",
    "510500000000": "泸州市",
    "510600000000": "德阳市",
    "510700000000": "绵阳市",
    "510800000000": "广元市",
    "510900000000": "遂宁市",
    "511000000000": "内江市",
    "511100000000": "乐山市",
    "511300000000": "南充市",
    "511400000000": "眉山市",
    "511500000000": "宜宾市",
    "511600000000": "广安市",
    "511700000000": "达州市",
    "511800000000": "雅安市",
    "511900000000": "巴中市",
    "512000000000": "资阳市",
    "513200000000": "阿坝藏族羌族自治州",
    "513300000000": "甘孜藏族自治州",
    "513400000000": "凉山彝族自治州",
    "520100000000": "贵阳市",
    "520200000000": "六盘水市",
    "520300000000": "遵义市",
    "520400000000": "安顺市",
    "520500000000": "毕节市",
    "520600000000": "铜仁市",
    "522300000000": "黔西南布依族苗族自治州",
    "522600000000": "黔东南苗族侗族自治州",
    "522700000000": "黔南布依族苗族自治州",
    "530100000000": "昆明市",
    "530300000000": "曲靖市",
    "530400000000": "玉溪市",
    "530500000000": "保山市",
    "530600000000": "昭通市",
    "530700000000": "丽江市",
    "530800000000": "普洱市",
    "530900000000": "临沧市",
    "532300000000": "楚雄彝族自治州",
    "532500000000": "红河哈尼族彝族自治州",
    "532600000000": "文山壮族苗族自治州",
    "532800000000": "西双版纳傣族自治州",
    "532900000000": "大理白族自治州",
    "533100000000": "德宏傣族景颇族自治州",
    "533300000000": "怒江傈僳族自治州",
    "533400000000": "迪庆藏族自治州",
    "540100000000": "拉萨市",
    "540200000000": "日喀则市",
    "540300000000": "昌都市",
    "540400000000": "林芝市",
    "540500000000": "山南市",
    "540600000000": "那曲市",
    "542500000000": "阿里地区",
    "610100000000": "西安市",
    "610200000000": "铜川市",
    "610300000000": "宝鸡市",
    "610400000000": "咸阳市",
    "610500000000": "渭南市",
    "610600000000": "延安市",
    "610700000000": "汉中市",
    "610800000000": "榆林市",
    "610900000000": "安康市",
    "611000000000": "商洛市",
    "620100000000": "兰州市",
    "620200000000": "嘉峪关市",
    "620300000000": "金昌市",
    "620400000000": "白银市",
    "620500000000": "天水市",
    "620600000000": "武威市",
    "620700000000": "张掖市",
    "620800000000": "平凉市",
    "620900000000": "酒泉市",
    "621000000000": "庆阳市",
    "621100000000": "定西市",
    "621200000000": "陇南市",
    "622900000000": "临夏回族自治州",
    "623000000000": "甘南藏族自治州",
    "630100000000": "西宁市",
    "630200000000": "海东市",
    "632200000000": "海北藏族自治州",
    "632300000000": "黄南藏族自治州",
    "632500000000": "海南藏族自治州",
    "632600000000": "果洛藏族自治州",
    "632700000000": "玉树藏族自治州",
    "632800000000": "海西蒙古族藏族自治州",
    "640100000000": "银川市",
    "640200000000": "石嘴山市",
    "640300000000": "吴忠市",
    "640400000000": "固原市",
    "640500000000": "中卫市",
    "650100000000": "乌鲁木齐市",
    "650200000000": "克拉玛依市",
    "650400000000": "吐鲁番市",
    "650500000000": "哈密市",
    "652300000000": "昌吉回族自治州",
    "652700000000": "博尔塔拉蒙古自治州",
    "652800000000": "巴音郭楞蒙古自治州",
    "652900000000": "阿克苏地区",
    "653000000000": "克孜勒苏柯尔克孜自治州",
    "653100000000": "喀什地区",
    "653200000000": "和田地区",
    "654000000000": "伊犁哈萨克自治州",
    "654200000000": "塔城地区",
    "654300000000": "阿勒泰地区",
    "659000000000": "自治区直辖县级行政区划"
  },
  "county_list": {
    "110101000000": "东城区",
    "110102000000": "西城区",
    "110105000000": "朝阳区",
    "110106000000": "丰台区",
    "110107000000": "石景山区",
    "110108000000": "海淀区",
    "110109000000": "门头沟区",
    "110111000000": "房山区",
    "110112000000": "通州区",
    "110113000000": "顺义区",
    "110114000000": "昌平区",
    "110115000000": "大兴区",
    "110116000000": "怀柔区",
    "110117000000": "平谷区",
    "110118000000": "密云区",
    "110119000000": "延庆区",
    "120101000000": "和平区",
    "120102000000": "河东区",
    "120103000000": "河西区",
    "120104000000": "南开区",
    "120105000000": "河北区",
    "120106000000": "红桥区",
    "120110000000": "东丽区",
    "120111000000": "西青区",
    "120112000000": "津南区",
    "120113000000": "北辰区",
    "120114000000": "武清区",
    "120115000000": "宝坻区",
    "120116000000": "滨海新区",
    "120117000000": "宁河区",
    "120118000000": "静海区",
    "120119000000": "蓟州区",
    "130102000000": "长安区",
    "130104000000": "桥西区",
    "130105000000": "新华区",
    "130107000000": "井陉矿区",
    "130108000000": "裕华区",
    "130109000000": "藁城区",
    "130110000000": "鹿泉区",
    "130111000000": "栾城区",
    "130121000000": "井陉县",
    "130123000000": "正定县",
    "130125000000": "行唐县",
    "130126000000": "灵寿县",
    "130127000000": "高邑县",
    "130128000000": "深泽县",
    "130129000000": "赞皇县",
    "130130000000": "无极县",
    "130131000000": "平山县",
    "130132000000": "元氏县",
    "130133000000": "赵县",
    "130171000000": "石家庄高新技术产业开发区",
    "130172000000": "石家庄循环化工园区",
    "130181000000": "辛集市",
    "130183000000": "晋州市",
    "130184000000": "新乐市",
    "130202000000": "路南区",
    "130203000000": "路北区",
    "130204000000": "古冶区",
    "130205000000": "开平区",
    "130207000000": "丰南区",
    "130208000000": "丰润区",
    "130209000000": "曹妃甸区",
    "130224000000": "滦南县",
    "130225000000": "乐亭县",
    "130227000000": "迁西县",
    "130229000000": "玉田县",
    "130271000000": "河北唐山芦台经济开发区",
    "130272000000": "唐山市汉沽管理区",
    "130273000000": "唐山高新技术产业开发区",
    "130274000000": "河北唐山海港经济开发区",
    "130281000000": "遵化市",
    "130283000000": "迁安市",
    "130284000000": "滦州市",
    "130302000000": "海港区",
    "130303000000": "山海关区",
    "130304000000": "北戴河区",
    "130306000000": "抚宁区",
    "130321000000": "青龙满族自治县",
    "130322000000": "昌黎县",
    "130324000000": "卢龙县",
    "130371000000": "秦皇岛市经济技术开发区",
    "130372000000": "北戴河新区",
    "130402000000": "邯山区",
    "130403000000": "丛台区",
    "130404000000": "复兴区",
    "130406000000": "峰峰矿区",
    "130407000000": "肥乡区",
    "130408000000": "永年区",
    "130423000000": "临漳县",
    "130424000000": "成安县",
    "130425000000": "大名县",
    "130426000000": "涉县",
    "130427000000": "磁县",
    "130430000000": "邱县",
    "130431000000": "鸡泽县",
    "130432000000": "广平县",
    "130433000000": "馆陶县",
    "130434000000": "魏县",
    "130435000000": "曲周县",
    "130471000000": "邯郸经济技术开发区",
    "130473000000": "邯郸冀南新区",
    "130481000000": "武安市",
    "130502000000": "襄都区",
    "130503000000": "信都区",
    "130521000000": "邢台县",
    "130522000000": "临城县",
    "130523000000": "内丘县",
    "130524000000": "柏乡县",
    "130525000000": "隆尧县",
    "130526000000": "任县",
    "130527000000": "南和县",
    "130528000000": "宁晋县",
    "130529000000": "巨鹿县",
    "130530000000": "新河县",
    "130531000000": "广宗县",
    "130532000000": "平乡县",
    "130533000000": "威县",
    "130534000000": "清河县",
    "130535000000": "临西县",
    "130571000000": "河北邢台经济开发区",
    "130581000000": "南宫市",
    "130582000000": "沙河市",
    "130602000000": "竞秀区",
    "130606000000": "莲池区",
    "130607000000": "满城区",
    "130608000000": "清苑区",
    "130609000000": "徐水区",
    "130623000000": "涞水县",
    "130624000000": "阜平县",
    "130626000000": "定兴县",
    "130627000000": "唐县",
    "130628000000": "高阳县",
    "130629000000": "容城县",
    "130630000000": "涞源县",
    "130631000000": "望都县",
    "130632000000": "安新县",
    "130633000000": "易县",
    "130634000000": "曲阳县",
    "130635000000": "蠡县",
    "130636000000": "顺平县",
    "130637000000": "博野县",
    "130638000000": "雄县",
    "130671000000": "保定高新技术产业开发区",
    "130672000000": "保定白沟新城",
    "130681000000": "涿州市",
    "130682000000": "定州市",
    "130683000000": "安国市",
    "130684000000": "高碑店市",
    "130702000000": "桥东区",
    "130703000000": "桥西区",
    "130705000000": "宣化区",
    "130706000000": "下花园区",
    "130708000000": "万全区",
    "130709000000": "崇礼区",
    "130722000000": "张北县",
    "130723000000": "康保县",
    "130724000000": "沽源县",
    "130725000000": "尚义县",
    "130726000000": "蔚县",
    "130727000000": "阳原县",
    "130728000000": "怀安县",
    "130730000000": "怀来县",
    "130731000000": "涿鹿县",
    "130732000000": "赤城县",
    "130771000000": "张家口经济开发区",
    "130772000000": "张家口市察北管理区",
    "130773000000": "张家口市塞北管理区",
    "130802000000": "双桥区",
    "130803000000": "双滦区",
    "130804000000": "鹰手营子矿区",
    "130821000000": "承德县",
    "130822000000": "兴隆县",
    "130824000000": "滦平县",
    "130825000000": "隆化县",
    "130826000000": "丰宁满族自治县",
    "130827000000": "宽城满族自治县",
    "130828000000": "围场满族蒙古族自治县",
    "130871000000": "承德高新技术产业开发区",
    "130881000000": "平泉市",
    "130902000000": "新华区",
    "130903000000": "运河区",
    "130921000000": "沧县",
    "130922000000": "青县",
    "130923000000": "东光县",
    "130924000000": "海兴县",
    "130925000000": "盐山县",
    "130926000000": "肃宁县",
    "130927000000": "南皮县",
    "130928000000": "吴桥县",
    "130929000000": "献县",
    "130930000000": "孟村回族自治县",
    "130971000000": "河北沧州经济开发区",
    "130972000000": "沧州高新技术产业开发区",
    "130973000000": "沧州渤海新区",
    "130981000000": "泊头市",
    "130982000000": "任丘市",
    "130983000000": "黄骅市",
    "130984000000": "河间市",
    "131002000000": "安次区",
    "131003000000": "广阳区",
    "131022000000": "固安县",
    "131023000000": "永清县",
    "131024000000": "香河县",
    "131025000000": "大城县",
    "131026000000": "文安县",
    "131028000000": "大厂回族自治县",
    "131071000000": "廊坊经济技术开发区",
    "131081000000": "霸州市",
    "131082000000": "三河市",
    "131102000000": "桃城区",
    "131103000000": "冀州区",
    "131121000000": "枣强县",
    "131122000000": "武邑县",
    "131123000000": "武强县",
    "131124000000": "饶阳县",
    "131125000000": "安平县",
    "131126000000": "故城县",
    "131127000000": "景县",
    "131128000000": "阜城县",
    "131171000000": "河北衡水高新技术产业开发区",
    "131172000000": "衡水滨湖新区",
    "131182000000": "深州市",
    "140105000000": "小店区",
    "140106000000": "迎泽区",
    "140107000000": "杏花岭区",
    "140108000000": "尖草坪区",
    "140109000000": "万柏林区",
    "140110000000": "晋源区",
    "140121000000": "清徐县",
    "140122000000": "阳曲县",
    "140123000000": "娄烦县",
    "140171000000": "山西转型综合改革示范区",
    "140181000000": "古交市",
    "140212000000": "新荣区",
    "140213000000": "平城区",
    "140214000000": "云冈区",
    "140215000000": "云州区",
    "140221000000": "阳高县",
    "140222000000": "天镇县",
    "140223000000": "广灵县",
    "140224000000": "灵丘县",
    "140225000000": "浑源县",
    "140226000000": "左云县",
    "140271000000": "山西大同经济开发区",
    "140302000000": "城区",
    "140303000000": "矿区",
    "140311000000": "郊区",
    "140321000000": "平定县",
    "140322000000": "盂县",
    "140403000000": "潞州区",
    "140404000000": "上党区",
    "140405000000": "屯留区",
    "140406000000": "潞城区",
    "140423000000": "襄垣县",
    "140425000000": "平顺县",
    "140426000000": "黎城县",
    "140427000000": "壶关县",
    "140428000000": "长子县",
    "140429000000": "武乡县",
    "140430000000": "沁县",
    "140431000000": "沁源县",
    "140471000000": "山西长治高新技术产业园区",
    "140502000000": "城区",
    "140521000000": "沁水县",
    "140522000000": "阳城县",
    "140524000000": "陵川县",
    "140525000000": "泽州县",
    "140581000000": "高平市",
    "140602000000": "朔城区",
    "140603000000": "平鲁区",
    "140621000000": "山阴县",
    "140622000000": "应县",
    "140623000000": "右玉县",
    "140671000000": "山西朔州经济开发区",
    "140681000000": "怀仁市",
    "140702000000": "榆次区",
    "140721000000": "榆社县",
    "140722000000": "左权县",
    "140723000000": "和顺县",
    "140724000000": "昔阳县",
    "140725000000": "寿阳县",
    "140726000000": "太谷县",
    "140727000000": "祁县",
    "140728000000": "平遥县",
    "140729000000": "灵石县",
    "140781000000": "介休市",
    "140802000000": "盐湖区",
    "140821000000": "临猗县",
    "140822000000": "万荣县",
    "140823000000": "闻喜县",
    "140824000000": "稷山县",
    "140825000000": "新绛县",
    "140826000000": "绛县",
    "140827000000": "垣曲县",
    "140828000000": "夏县",
    "140829000000": "平陆县",
    "140830000000": "芮城县",
    "140881000000": "永济市",
    "140882000000": "河津市",
    "140902000000": "忻府区",
    "140921000000": "定襄县",
    "140922000000": "五台县",
    "140923000000": "代县",
    "140924000000": "繁峙县",
    "140925000000": "宁武县",
    "140926000000": "静乐县",
    "140927000000": "神池县",
    "140928000000": "五寨县",
    "140929000000": "岢岚县",
    "140930000000": "河曲县",
    "140931000000": "保德县",
    "140932000000": "偏关县",
    "140971000000": "五台山风景名胜区",
    "140981000000": "原平市",
    "141002000000": "尧都区",
    "141021000000": "曲沃县",
    "141022000000": "翼城县",
    "141023000000": "襄汾县",
    "141024000000": "洪洞县",
    "141025000000": "古县",
    "141026000000": "安泽县",
    "141027000000": "浮山县",
    "141028000000": "吉县",
    "141029000000": "乡宁县",
    "141030000000": "大宁县",
    "141031000000": "隰县",
    "141032000000": "永和县",
    "141033000000": "蒲县",
    "141034000000": "汾西县",
    "141081000000": "侯马市",
    "141082000000": "霍州市",
    "141102000000": "离石区",
    "141121000000": "文水县",
    "141122000000": "交城县",
    "141123000000": "兴县",
    "141124000000": "临县",
    "141125000000": "柳林县",
    "141126000000": "石楼县",
    "141127000000": "岚县",
    "141128000000": "方山县",
    "141129000000": "中阳县",
    "141130000000": "交口县",
    "141181000000": "孝义市",
    "141182000000": "汾阳市",
    "150102000000": "新城区",
    "150103000000": "回民区",
    "150104000000": "玉泉区",
    "150105000000": "赛罕区",
    "150121000000": "土默特左旗",
    "150122000000": "托克托县",
    "150123000000": "和林格尔县",
    "150124000000": "清水河县",
    "150125000000": "武川县",
    "150171000000": "呼和浩特金海工业园区",
    "150172000000": "呼和浩特经济技术开发区",
    "150202000000": "东河区",
    "150203000000": "昆都仑区",
    "150204000000": "青山区",
    "150205000000": "石拐区",
    "150206000000": "白云鄂博矿区",
    "150207000000": "九原区",
    "150221000000": "土默特右旗",
    "150222000000": "固阳县",
    "150223000000": "达尔罕茂明安联合旗",
    "150271000000": "包头稀土高新技术产业开发区",
    "150302000000": "海勃湾区",
    "150303000000": "海南区",
    "150304000000": "乌达区",
    "150402000000": "红山区",
    "150403000000": "元宝山区",
    "150404000000": "松山区",
    "150421000000": "阿鲁科尔沁旗",
    "150422000000": "巴林左旗",
    "150423000000": "巴林右旗",
    "150424000000": "林西县",
    "150425000000": "克什克腾旗",
    "150426000000": "翁牛特旗",
    "150428000000": "喀喇沁旗",
    "150429000000": "宁城县",
    "150430000000": "敖汉旗",
    "150502000000": "科尔沁区",
    "150521000000": "科尔沁左翼中旗",
    "150522000000": "科尔沁左翼后旗",
    "150523000000": "开鲁县",
    "150524000000": "库伦旗",
    "150525000000": "奈曼旗",
    "150526000000": "扎鲁特旗",
    "150571000000": "通辽经济技术开发区",
    "150581000000": "霍林郭勒市",
    "150602000000": "东胜区",
    "150603000000": "康巴什区",
    "150621000000": "达拉特旗",
    "150622000000": "准格尔旗",
    "150623000000": "鄂托克前旗",
    "150624000000": "鄂托克旗",
    "150625000000": "杭锦旗",
    "150626000000": "乌审旗",
    "150627000000": "伊金霍洛旗",
    "150702000000": "海拉尔区",
    "150703000000": "扎赉诺尔区",
    "150721000000": "阿荣旗",
    "150722000000": "莫力达瓦达斡尔族自治旗",
    "150723000000": "鄂伦春自治旗",
    "150724000000": "鄂温克族自治旗",
    "150725000000": "陈巴尔虎旗",
    "150726000000": "新巴尔虎左旗",
    "150727000000": "新巴尔虎右旗",
    "150781000000": "满洲里市",
    "150782000000": "牙克石市",
    "150783000000": "扎兰屯市",
    "150784000000": "额尔古纳市",
    "150785000000": "根河市",
    "150802000000": "临河区",
    "150821000000": "五原县",
    "150822000000": "磴口县",
    "150823000000": "乌拉特前旗",
    "150824000000": "乌拉特中旗",
    "150825000000": "乌拉特后旗",
    "150826000000": "杭锦后旗",
    "150902000000": "集宁区",
    "150921000000": "卓资县",
    "150922000000": "化德县",
    "150923000000": "商都县",
    "150924000000": "兴和县",
    "150925000000": "凉城县",
    "150926000000": "察哈尔右翼前旗",
    "150927000000": "察哈尔右翼中旗",
    "150928000000": "察哈尔右翼后旗",
    "150929000000": "四子王旗",
    "150981000000": "丰镇市",
    "152201000000": "乌兰浩特市",
    "152202000000": "阿尔山市",
    "152221000000": "科尔沁右翼前旗",
    "152222000000": "科尔沁右翼中旗",
    "152223000000": "扎赉特旗",
    "152224000000": "突泉县",
    "152501000000": "二连浩特市",
    "152502000000": "锡林浩特市",
    "152522000000": "阿巴嘎旗",
    "152523000000": "苏尼特左旗",
    "152524000000": "苏尼特右旗",
    "152525000000": "东乌珠穆沁旗",
    "152526000000": "西乌珠穆沁旗",
    "152527000000": "太仆寺旗",
    "152528000000": "镶黄旗",
    "152529000000": "正镶白旗",
    "152530000000": "正蓝旗",
    "152531000000": "多伦县",
    "152571000000": "乌拉盖管委会",
    "152921000000": "阿拉善左旗",
    "152922000000": "阿拉善右旗",
    "152923000000": "额济纳旗",
    "152971000000": "内蒙古阿拉善经济开发区",
    "210102000000": "和平区",
    "210103000000": "沈河区",
    "210104000000": "大东区",
    "210105000000": "皇姑区",
    "210106000000": "铁西区",
    "210111000000": "苏家屯区",
    "210112000000": "浑南区",
    "210113000000": "沈北新区",
    "210114000000": "于洪区",
    "210115000000": "辽中区",
    "210123000000": "康平县",
    "210124000000": "法库县",
    "210181000000": "新民市",
    "210202000000": "中山区",
    "210203000000": "西岗区",
    "210204000000": "沙河口区",
    "210211000000": "甘井子区",
    "210212000000": "旅顺口区",
    "210213000000": "金州区",
    "210214000000": "普兰店区",
    "210224000000": "长海县",
    "210281000000": "瓦房店市",
    "210283000000": "庄河市",
    "210302000000": "铁东区",
    "210303000000": "铁西区",
    "210304000000": "立山区",
    "210311000000": "千山区",
    "210321000000": "台安县",
    "210323000000": "岫岩满族自治县",
    "210381000000": "海城市",
    "210402000000": "新抚区",
    "210403000000": "东洲区",
    "210404000000": "望花区",
    "210411000000": "顺城区",
    "210421000000": "抚顺县",
    "210422000000": "新宾满族自治县",
    "210423000000": "清原满族自治县",
    "210502000000": "平山区",
    "210503000000": "溪湖区",
    "210504000000": "明山区",
    "210505000000": "南芬区",
    "210521000000": "本溪满族自治县",
    "210522000000": "桓仁满族自治县",
    "210602000000": "元宝区",
    "210603000000": "振兴区",
    "210604000000": "振安区",
    "210624000000": "宽甸满族自治县",
    "210681000000": "东港市",
    "210682000000": "凤城市",
    "210702000000": "古塔区",
    "210703000000": "凌河区",
    "210711000000": "太和区",
    "210726000000": "黑山县",
    "210727000000": "义县",
    "210781000000": "凌海市",
    "210782000000": "北镇市",
    "210802000000": "站前区",
    "210803000000": "西市区",
    "210804000000": "鲅鱼圈区",
    "210811000000": "老边区",
    "210881000000": "盖州市",
    "210882000000": "大石桥市",
    "210902000000": "海州区",
    "210903000000": "新邱区",
    "210904000000": "太平区",
    "210905000000": "清河门区",
    "210911000000": "细河区",
    "210921000000": "阜新蒙古族自治县",
    "210922000000": "彰武县",
    "211002000000": "白塔区",
    "211003000000": "文圣区",
    "211004000000": "宏伟区",
    "211005000000": "弓长岭区",
    "211011000000": "太子河区",
    "211021000000": "辽阳县",
    "211081000000": "灯塔市",
    "211102000000": "双台子区",
    "211103000000": "兴隆台区",
    "211104000000": "大洼区",
    "211122000000": "盘山县",
    "211202000000": "银州区",
    "211204000000": "清河区",
    "211221000000": "铁岭县",
    "211223000000": "西丰县",
    "211224000000": "昌图县",
    "211281000000": "调兵山市",
    "211282000000": "开原市",
    "211302000000": "双塔区",
    "211303000000": "龙城区",
    "211321000000": "朝阳县",
    "211322000000": "建平县",
    "211324000000": "喀喇沁左翼蒙古族自治县",
    "211381000000": "北票市",
    "211382000000": "凌源市",
    "211402000000": "连山区",
    "211403000000": "龙港区",
    "211404000000": "南票区",
    "211421000000": "绥中县",
    "211422000000": "建昌县",
    "211481000000": "兴城市",
    "220102000000": "南关区",
    "220103000000": "宽城区",
    "220104000000": "朝阳区",
    "220105000000": "二道区",
    "220106000000": "绿园区",
    "220112000000": "双阳区",
    "220113000000": "九台区",
    "220122000000": "农安县",
    "220171000000": "长春经济技术开发区",
    "220172000000": "长春净月高新技术产业开发区",
    "220173000000": "长春高新技术产业开发区",
    "220174000000": "长春汽车经济技术开发区",
    "220182000000": "榆树市",
    "220183000000": "德惠市",
    "220202000000": "昌邑区",
    "220203000000": "龙潭区",
    "220204000000": "船营区",
    "220211000000": "丰满区",
    "220221000000": "永吉县",
    "220271000000": "吉林经济开发区",
    "220272000000": "吉林高新技术产业开发区",
    "220273000000": "吉林中国新加坡食品区",
    "220281000000": "蛟河市",
    "220282000000": "桦甸市",
    "220283000000": "舒兰市",
    "220284000000": "磐石市",
    "220302000000": "铁西区",
    "220303000000": "铁东区",
    "220322000000": "梨树县",
    "220323000000": "伊通满族自治县",
    "220381000000": "公主岭市",
    "220382000000": "双辽市",
    "220402000000": "龙山区",
    "220403000000": "西安区",
    "220421000000": "东丰县",
    "220422000000": "东辽县",
    "220502000000": "东昌区",
    "220503000000": "二道江区",
    "220521000000": "通化县",
    "220523000000": "辉南县",
    "220524000000": "柳河县",
    "220581000000": "梅河口市",
    "220582000000": "集安市",
    "220602000000": "浑江区",
    "220605000000": "江源区",
    "220621000000": "抚松县",
    "220622000000": "靖宇县",
    "220623000000": "长白朝鲜族自治县",
    "220681000000": "临江市",
    "220702000000": "宁江区",
    "220721000000": "前郭尔罗斯蒙古族自治县",
    "220722000000": "长岭县",
    "220723000000": "乾安县",
    "220771000000": "吉林松原经济开发区",
    "220781000000": "扶余市",
    "220802000000": "洮北区",
    "220821000000": "镇赉县",
    "220822000000": "通榆县",
    "220871000000": "吉林白城经济开发区",
    "220881000000": "洮南市",
    "220882000000": "大安市",
    "222401000000": "延吉市",
    "222402000000": "图们市",
    "222403000000": "敦化市",
    "222404000000": "珲春市",
    "222405000000": "龙井市",
    "222406000000": "和龙市",
    "222424000000": "汪清县",
    "222426000000": "安图县",
    "230102000000": "道里区",
    "230103000000": "南岗区",
    "230104000000": "道外区",
    "230108000000": "平房区",
    "230109000000": "松北区",
    "230110000000": "香坊区",
    "230111000000": "呼兰区",
    "230112000000": "阿城区",
    "230113000000": "双城区",
    "230123000000": "依兰县",
    "230124000000": "方正县",
    "230125000000": "宾县",
    "230126000000": "巴彦县",
    "230127000000": "木兰县",
    "230128000000": "通河县",
    "230129000000": "延寿县",
    "230183000000": "尚志市",
    "230184000000": "五常市",
    "230202000000": "龙沙区",
    "230203000000": "建华区",
    "230204000000": "铁锋区",
    "230205000000": "昂昂溪区",
    "230206000000": "富拉尔基区",
    "230207000000": "碾子山区",
    "230208000000": "梅里斯达斡尔族区",
    "230221000000": "龙江县",
    "230223000000": "依安县",
    "230224000000": "泰来县",
    "230225000000": "甘南县",
    "230227000000": "富裕县",
    "230229000000": "克山县",
    "230230000000": "克东县",
    "230231000000": "拜泉县",
    "230281000000": "讷河市",
    "230302000000": "鸡冠区",
    "230303000000": "恒山区",
    "230304000000": "滴道区",
    "230305000000": "梨树区",
    "230306000000": "城子河区",
    "230307000000": "麻山区",
    "230321000000": "鸡东县",
    "230381000000": "虎林市",
    "230382000000": "密山市",
    "230402000000": "向阳区",
    "230403000000": "工农区",
    "230404000000": "南山区",
    "230405000000": "兴安区",
    "230406000000": "东山区",
    "230407000000": "兴山区",
    "230421000000": "萝北县",
    "230422000000": "绥滨县",
    "230502000000": "尖山区",
    "230503000000": "岭东区",
    "230505000000": "四方台区",
    "230506000000": "宝山区",
    "230521000000": "集贤县",
    "230522000000": "友谊县",
    "230523000000": "宝清县",
    "230524000000": "饶河县",
    "230602000000": "萨尔图区",
    "230603000000": "龙凤区",
    "230604000000": "让胡路区",
    "230605000000": "红岗区",
    "230606000000": "大同区",
    "230621000000": "肇州县",
    "230622000000": "肇源县",
    "230623000000": "林甸县",
    "230624000000": "杜尔伯特蒙古族自治县",
    "230671000000": "大庆高新技术产业开发区",
    "230717000000": "伊美区",
    "230718000000": "乌翠区",
    "230719000000": "友好区",
    "230722000000": "嘉荫县",
    "230723000000": "汤旺县",
    "230724000000": "丰林县",
    "230725000000": "大箐山县",
    "230726000000": "南岔县",
    "230751000000": "金林区",
    "230781000000": "铁力市",
    "230803000000": "向阳区",
    "230804000000": "前进区",
    "230805000000": "东风区",
    "230811000000": "郊区",
    "230822000000": "桦南县",
    "230826000000": "桦川县",
    "230828000000": "汤原县",
    "230881000000": "同江市",
    "230882000000": "富锦市",
    "230883000000": "抚远市",
    "230902000000": "新兴区",
    "230903000000": "桃山区",
    "230904000000": "茄子河区",
    "230921000000": "勃利县",
    "231002000000": "东安区",
    "231003000000": "阳明区",
    "231004000000": "爱民区",
    "231005000000": "西安区",
    "231025000000": "林口县",
    "231071000000": "牡丹江经济技术开发区",
    "231081000000": "绥芬河市",
    "231083000000": "海林市",
    "231084000000": "宁安市",
    "231085000000": "穆棱市",
    "231086000000": "东宁市",
    "231102000000": "爱辉区",
    "231123000000": "逊克县",
    "231124000000": "孙吴县",
    "231181000000": "北安市",
    "231182000000": "五大连池市",
    "231183000000": "嫩江市",
    "231202000000": "北林区",
    "231221000000": "望奎县",
    "231222000000": "兰西县",
    "231223000000": "青冈县",
    "231224000000": "庆安县",
    "231225000000": "明水县",
    "231226000000": "绥棱县",
    "231281000000": "安达市",
    "231282000000": "肇东市",
    "231283000000": "海伦市",
    "232701000000": "漠河市",
    "232721000000": "呼玛县",
    "232722000000": "塔河县",
    "232761000000": "加格达奇区",
    "232762000000": "松岭区",
    "232763000000": "新林区",
    "232764000000": "呼中区",
    "310101000000": "黄浦区",
    "310104000000": "徐汇区",
    "310105000000": "长宁区",
    "310106000000": "静安区",
    "310107000000": "普陀区",
    "310109000000": "虹口区",
    "310110000000": "杨浦区",
    "310112000000": "闵行区",
    "310113000000": "宝山区",
    "310114000000": "嘉定区",
    "310115000000": "浦东新区",
    "310116000000": "金山区",
    "310117000000": "松江区",
    "310118000000": "青浦区",
    "310120000000": "奉贤区",
    "310151000000": "崇明区",
    "320102000000": "玄武区",
    "320104000000": "秦淮区",
    "320105000000": "建邺区",
    "320106000000": "鼓楼区",
    "320111000000": "浦口区",
    "320113000000": "栖霞区",
    "320114000000": "雨花台区",
    "320115000000": "江宁区",
    "320116000000": "六合区",
    "320117000000": "溧水区",
    "320118000000": "高淳区",
    "320205000000": "锡山区",
    "320206000000": "惠山区",
    "320211000000": "滨湖区",
    "320213000000": "梁溪区",
    "320214000000": "新吴区",
    "320281000000": "江阴市",
    "320282000000": "宜兴市",
    "320302000000": "鼓楼区",
    "320303000000": "云龙区",
    "320305000000": "贾汪区",
    "320311000000": "泉山区",
    "320312000000": "铜山区",
    "320321000000": "丰县",
    "320322000000": "沛县",
    "320324000000": "睢宁县",
    "320371000000": "徐州经济技术开发区",
    "320381000000": "新沂市",
    "320382000000": "邳州市",
    "320402000000": "天宁区",
    "320404000000": "钟楼区",
    "320411000000": "新北区",
    "320412000000": "武进区",
    "320413000000": "金坛区",
    "320481000000": "溧阳市",
    "320505000000": "虎丘区",
    "320506000000": "吴中区",
    "320507000000": "相城区",
    "320508000000": "姑苏区",
    "320509000000": "吴江区",
    "320571000000": "苏州工业园区",
    "320581000000": "常熟市",
    "320582000000": "张家港市",
    "320583000000": "昆山市",
    "320585000000": "太仓市",
    "320602000000": "崇川区",
    "320611000000": "港闸区",
    "320612000000": "通州区",
    "320623000000": "如东县",
    "320671000000": "南通经济技术开发区",
    "320681000000": "启东市",
    "320682000000": "如皋市",
    "320684000000": "海门市",
    "320685000000": "海安市",
    "320703000000": "连云区",
    "320706000000": "海州区",
    "320707000000": "赣榆区",
    "320722000000": "东海县",
    "320723000000": "灌云县",
    "320724000000": "灌南县",
    "320771000000": "连云港经济技术开发区",
    "320772000000": "连云港高新技术产业开发区",
    "320803000000": "淮安区",
    "320804000000": "淮阴区",
    "320812000000": "清江浦区",
    "320813000000": "洪泽区",
    "320826000000": "涟水县",
    "320830000000": "盱眙县",
    "320831000000": "金湖县",
    "320871000000": "淮安经济技术开发区",
    "320902000000": "亭湖区",
    "320903000000": "盐都区",
    "320904000000": "大丰区",
    "320921000000": "响水县",
    "320922000000": "滨海县",
    "320923000000": "阜宁县",
    "320924000000": "射阳县",
    "320925000000": "建湖县",
    "320971000000": "盐城经济技术开发区",
    "320981000000": "东台市",
    "321002000000": "广陵区",
    "321003000000": "邗江区",
    "321012000000": "江都区",
    "321023000000": "宝应县",
    "321071000000": "扬州经济技术开发区",
    "321081000000": "仪征市",
    "321084000000": "高邮市",
    "321102000000": "京口区",
    "321111000000": "润州区",
    "321112000000": "丹徒区",
    "321171000000": "镇江新区",
    "321181000000": "丹阳市",
    "321182000000": "扬中市",
    "321183000000": "句容市",
    "321202000000": "海陵区",
    "321203000000": "高港区",
    "321204000000": "姜堰区",
    "321271000000": "泰州医药高新技术产业开发区",
    "321281000000": "兴化市",
    "321282000000": "靖江市",
    "321283000000": "泰兴市",
    "321302000000": "宿城区",
    "321311000000": "宿豫区",
    "321322000000": "沭阳县",
    "321323000000": "泗阳县",
    "321324000000": "泗洪县",
    "321371000000": "宿迁经济技术开发区",
    "330102000000": "上城区",
    "330103000000": "下城区",
    "330104000000": "江干区",
    "330105000000": "拱墅区",
    "330106000000": "西湖区",
    "330108000000": "滨江区",
    "330109000000": "萧山区",
    "330110000000": "余杭区",
    "330111000000": "富阳区",
    "330112000000": "临安区",
    "330122000000": "桐庐县",
    "330127000000": "淳安县",
    "330182000000": "建德市",
    "330203000000": "海曙区",
    "330205000000": "江北区",
    "330206000000": "北仑区",
    "330211000000": "镇海区",
    "330212000000": "鄞州区",
    "330213000000": "奉化区",
    "330225000000": "象山县",
    "330226000000": "宁海县",
    "330281000000": "余姚市",
    "330282000000": "慈溪市",
    "330302000000": "鹿城区",
    "330303000000": "龙湾区",
    "330304000000": "瓯海区",
    "330305000000": "洞头区",
    "330324000000": "永嘉县",
    "330326000000": "平阳县",
    "330327000000": "苍南县",
    "330328000000": "文成县",
    "330329000000": "泰顺县",
    "330371000000": "温州经济技术开发区",
    "330381000000": "瑞安市",
    "330382000000": "乐清市",
    "330383000000": "龙港市",
    "330402000000": "南湖区",
    "330411000000": "秀洲区",
    "330421000000": "嘉善县",
    "330424000000": "海盐县",
    "330481000000": "海宁市",
    "330482000000": "平湖市",
    "330483000000": "桐乡市",
    "330502000000": "吴兴区",
    "330503000000": "南浔区",
    "330521000000": "德清县",
    "330522000000": "长兴县",
    "330523000000": "安吉县",
    "330602000000": "越城区",
    "330603000000": "柯桥区",
    "330604000000": "上虞区",
    "330624000000": "新昌县",
    "330681000000": "诸暨市",
    "330683000000": "嵊州市",
    "330702000000": "婺城区",
    "330703000000": "金东区",
    "330723000000": "武义县",
    "330726000000": "浦江县",
    "330727000000": "磐安县",
    "330781000000": "兰溪市",
    "330782000000": "义乌市",
    "330783000000": "东阳市",
    "330784000000": "永康市",
    "330802000000": "柯城区",
    "330803000000": "衢江区",
    "330822000000": "常山县",
    "330824000000": "开化县",
    "330825000000": "龙游县",
    "330881000000": "江山市",
    "330902000000": "定海区",
    "330903000000": "普陀区",
    "330921000000": "岱山县",
    "330922000000": "嵊泗县",
    "331002000000": "椒江区",
    "331003000000": "黄岩区",
    "331004000000": "路桥区",
    "331022000000": "三门县",
    "331023000000": "天台县",
    "331024000000": "仙居县",
    "331081000000": "温岭市",
    "331082000000": "临海市",
    "331083000000": "玉环市",
    "331102000000": "莲都区",
    "331121000000": "青田县",
    "331122000000": "缙云县",
    "331123000000": "遂昌县",
    "331124000000": "松阳县",
    "331125000000": "云和县",
    "331126000000": "庆元县",
    "331127000000": "景宁畲族自治县",
    "331181000000": "龙泉市",
    "340102000000": "瑶海区",
    "340103000000": "庐阳区",
    "340104000000": "蜀山区",
    "340111000000": "包河区",
    "340121000000": "长丰县",
    "340122000000": "肥东县",
    "340123000000": "肥西县",
    "340124000000": "庐江县",
    "340171000000": "合肥高新技术产业开发区",
    "340172000000": "合肥经济技术开发区",
    "340173000000": "合肥新站高新技术产业开发区",
    "340181000000": "巢湖市",
    "340202000000": "镜湖区",
    "340203000000": "弋江区",
    "340207000000": "鸠江区",
    "340208000000": "三山区",
    "340221000000": "芜湖县",
    "340222000000": "繁昌县",
    "340223000000": "南陵县",
    "340225000000": "无为县",
    "340271000000": "芜湖经济技术开发区",
    "340272000000": "安徽芜湖长江大桥经济开发区",
    "340302000000": "龙子湖区",
    "340303000000": "蚌山区",
    "340304000000": "禹会区",
    "340311000000": "淮上区",
    "340321000000": "怀远县",
    "340322000000": "五河县",
    "340323000000": "固镇县",
    "340371000000": "蚌埠市高新技术开发区",
    "340372000000": "蚌埠市经济开发区",
    "340402000000": "大通区",
    "340403000000": "田家庵区",
    "340404000000": "谢家集区",
    "340405000000": "八公山区",
    "340406000000": "潘集区",
    "340421000000": "凤台县",
    "340422000000": "寿县",
    "340503000000": "花山区",
    "340504000000": "雨山区",
    "340506000000": "博望区",
    "340521000000": "当涂县",
    "340522000000": "含山县",
    "340523000000": "和县",
    "340602000000": "杜集区",
    "340603000000": "相山区",
    "340604000000": "烈山区",
    "340621000000": "濉溪县",
    "340705000000": "铜官区",
    "340706000000": "义安区",
    "340711000000": "郊区",
    "340722000000": "枞阳县",
    "340802000000": "迎江区",
    "340803000000": "大观区",
    "340811000000": "宜秀区",
    "340822000000": "怀宁县",
    "340825000000": "太湖县",
    "340826000000": "宿松县",
    "340827000000": "望江县",
    "340828000000": "岳西县",
    "340871000000": "安徽安庆经济开发区",
    "340881000000": "桐城市",
    "340882000000": "潜山市",
    "341002000000": "屯溪区",
    "341003000000": "黄山区",
    "341004000000": "徽州区",
    "341021000000": "歙县",
    "341022000000": "休宁县",
    "341023000000": "黟县",
    "341024000000": "祁门县",
    "341102000000": "琅琊区",
    "341103000000": "南谯区",
    "341122000000": "来安县",
    "341124000000": "全椒县",
    "341125000000": "定远县",
    "341126000000": "凤阳县",
    "341171000000": "苏滁现代产业园",
    "341172000000": "滁州经济技术开发区",
    "341181000000": "天长市",
    "341182000000": "明光市",
    "341202000000": "颍州区",
    "341203000000": "颍东区",
    "341204000000": "颍泉区",
    "341221000000": "临泉县",
    "341222000000": "太和县",
    "341225000000": "阜南县",
    "341226000000": "颍上县",
    "341271000000": "阜阳合肥现代产业园区",
    "341272000000": "阜阳经济技术开发区",
    "341282000000": "界首市",
    "341302000000": "埇桥区",
    "341321000000": "砀山县",
    "341322000000": "萧县",
    "341323000000": "灵璧县",
    "341324000000": "泗县",
    "341371000000": "宿州马鞍山现代产业园区",
    "341372000000": "宿州经济技术开发区",
    "341502000000": "金安区",
    "341503000000": "裕安区",
    "341504000000": "叶集区",
    "341522000000": "霍邱县",
    "341523000000": "舒城县",
    "341524000000": "金寨县",
    "341525000000": "霍山县",
    "341602000000": "谯城区",
    "341621000000": "涡阳县",
    "341622000000": "蒙城县",
    "341623000000": "利辛县",
    "341702000000": "贵池区",
    "341721000000": "东至县",
    "341722000000": "石台县",
    "341723000000": "青阳县",
    "341802000000": "宣州区",
    "341821000000": "郎溪县",
    "341823000000": "泾县",
    "341824000000": "绩溪县",
    "341825000000": "旌德县",
    "341871000000": "宣城市经济开发区",
    "341881000000": "宁国市",
    "341882000000": "广德市",
    "350102000000": "鼓楼区",
    "350103000000": "台江区",
    "350104000000": "仓山区",
    "350105000000": "马尾区",
    "350111000000": "晋安区",
    "350112000000": "长乐区",
    "350121000000": "闽侯县",
    "350122000000": "连江县",
    "350123000000": "罗源县",
    "350124000000": "闽清县",
    "350125000000": "永泰县",
    "350128000000": "平潭县",
    "350181000000": "福清市",
    "350203000000": "思明区",
    "350205000000": "海沧区",
    "350206000000": "湖里区",
    "350211000000": "集美区",
    "350212000000": "同安区",
    "350213000000": "翔安区",
    "350302000000": "城厢区",
    "350303000000": "涵江区",
    "350304000000": "荔城区",
    "350305000000": "秀屿区",
    "350322000000": "仙游县",
    "350402000000": "梅列区",
    "350403000000": "三元区",
    "350421000000": "明溪县",
    "350423000000": "清流县",
    "350424000000": "宁化县",
    "350425000000": "大田县",
    "350426000000": "尤溪县",
    "350427000000": "沙县",
    "350428000000": "将乐县",
    "350429000000": "泰宁县",
    "350430000000": "建宁县",
    "350481000000": "永安市",
    "350502000000": "鲤城区",
    "350503000000": "丰泽区",
    "350504000000": "洛江区",
    "350505000000": "泉港区",
    "350521000000": "惠安县",
    "350524000000": "安溪县",
    "350525000000": "永春县",
    "350526000000": "德化县",
    "350581000000": "石狮市",
    "350582000000": "晋江市",
    "350583000000": "南安市",
    "350602000000": "芗城区",
    "350603000000": "龙文区",
    "350622000000": "云霄县",
    "350623000000": "漳浦县",
    "350624000000": "诏安县",
    "350625000000": "长泰县",
    "350626000000": "东山县",
    "350627000000": "南靖县",
    "350628000000": "平和县",
    "350629000000": "华安县",
    "350681000000": "龙海市",
    "350702000000": "延平区",
    "350703000000": "建阳区",
    "350721000000": "顺昌县",
    "350722000000": "浦城县",
    "350723000000": "光泽县",
    "350724000000": "松溪县",
    "350725000000": "政和县",
    "350781000000": "邵武市",
    "350782000000": "武夷山市",
    "350783000000": "建瓯市",
    "350802000000": "新罗区",
    "350803000000": "永定区",
    "350821000000": "长汀县",
    "350823000000": "上杭县",
    "350824000000": "武平县",
    "350825000000": "连城县",
    "350881000000": "漳平市",
    "350902000000": "蕉城区",
    "350921000000": "霞浦县",
    "350922000000": "古田县",
    "350923000000": "屏南县",
    "350924000000": "寿宁县",
    "350925000000": "周宁县",
    "350926000000": "柘荣县",
    "350981000000": "福安市",
    "350982000000": "福鼎市",
    "360102000000": "东湖区",
    "360103000000": "西湖区",
    "360104000000": "青云谱区",
    "360105000000": "湾里区",
    "360111000000": "青山湖区",
    "360112000000": "新建区",
    "360121000000": "南昌县",
    "360123000000": "安义县",
    "360124000000": "进贤县",
    "360202000000": "昌江区",
    "360203000000": "珠山区",
    "360222000000": "浮梁县",
    "360281000000": "乐平市",
    "360302000000": "安源区",
    "360313000000": "湘东区",
    "360321000000": "莲花县",
    "360322000000": "上栗县",
    "360323000000": "芦溪县",
    "360402000000": "濂溪区",
    "360403000000": "浔阳区",
    "360404000000": "柴桑区",
    "360423000000": "武宁县",
    "360424000000": "修水县",
    "360425000000": "永修县",
    "360426000000": "德安县",
    "360428000000": "都昌县",
    "360429000000": "湖口县",
    "360430000000": "彭泽县",
    "360481000000": "瑞昌市",
    "360482000000": "共青城市",
    "360483000000": "庐山市",
    "360502000000": "渝水区",
    "360521000000": "分宜县",
    "360602000000": "月湖区",
    "360603000000": "余江区",
    "360681000000": "贵溪市",
    "360702000000": "章贡区",
    "360703000000": "南康区",
    "360704000000": "赣县区",
    "360722000000": "信丰县",
    "360723000000": "大余县",
    "360724000000": "上犹县",
    "360725000000": "崇义县",
    "360726000000": "安远县",
    "360727000000": "龙南县",
    "360728000000": "定南县",
    "360729000000": "全南县",
    "360730000000": "宁都县",
    "360731000000": "于都县",
    "360732000000": "兴国县",
    "360733000000": "会昌县",
    "360734000000": "寻乌县",
    "360735000000": "石城县",
    "360781000000": "瑞金市",
    "360802000000": "吉州区",
    "360803000000": "青原区",
    "360821000000": "吉安县",
    "360822000000": "吉水县",
    "360823000000": "峡江县",
    "360824000000": "新干县",
    "360825000000": "永丰县",
    "360826000000": "泰和县",
    "360827000000": "遂川县",
    "360828000000": "万安县",
    "360829000000": "安福县",
    "360830000000": "永新县",
    "360881000000": "井冈山市",
    "360902000000": "袁州区",
    "360921000000": "奉新县",
    "360922000000": "万载县",
    "360923000000": "上高县",
    "360924000000": "宜丰县",
    "360925000000": "靖安县",
    "360926000000": "铜鼓县",
    "360981000000": "丰城市",
    "360982000000": "樟树市",
    "360983000000": "高安市",
    "361002000000": "临川区",
    "361003000000": "东乡区",
    "361021000000": "南城县",
    "361022000000": "黎川县",
    "361023000000": "南丰县",
    "361024000000": "崇仁县",
    "361025000000": "乐安县",
    "361026000000": "宜黄县",
    "361027000000": "金溪县",
    "361028000000": "资溪县",
    "361030000000": "广昌县",
    "361102000000": "信州区",
    "361103000000": "广丰区",
    "361104000000": "广信区",
    "361123000000": "玉山县",
    "361124000000": "铅山县",
    "361125000000": "横峰县",
    "361126000000": "弋阳县",
    "361127000000": "余干县",
    "361128000000": "鄱阳县",
    "361129000000": "万年县",
    "361130000000": "婺源县",
    "361181000000": "德兴市",
    "370102000000": "历下区",
    "370103000000": "市中区",
    "370104000000": "槐荫区",
    "370105000000": "天桥区",
    "370112000000": "历城区",
    "370113000000": "长清区",
    "370114000000": "章丘区",
    "370115000000": "济阳区",
    "370116000000": "莱芜区",
    "370117000000": "钢城区",
    "370124000000": "平阴县",
    "370126000000": "商河县",
    "370171000000": "济南高新技术产业开发区",
    "370202000000": "市南区",
    "370203000000": "市北区",
    "370211000000": "黄岛区",
    "370212000000": "崂山区",
    "370213000000": "李沧区",
    "370214000000": "城阳区",
    "370215000000": "即墨区",
    "370271000000": "青岛高新技术产业开发区",
    "370281000000": "胶州市",
    "370283000000": "平度市",
    "370285000000": "莱西市",
    "370302000000": "淄川区",
    "370303000000": "张店区",
    "370304000000": "博山区",
    "370305000000": "临淄区",
    "370306000000": "周村区",
    "370321000000": "桓台县",
    "370322000000": "高青县",
    "370323000000": "沂源县",
    "370402000000": "市中区",
    "370403000000": "薛城区",
    "370404000000": "峄城区",
    "370405000000": "台儿庄区",
    "370406000000": "山亭区",
    "370481000000": "滕州市",
    "370502000000": "东营区",
    "370503000000": "河口区",
    "370505000000": "垦利区",
    "370522000000": "利津县",
    "370523000000": "广饶县",
    "370571000000": "东营经济技术开发区",
    "370572000000": "东营港经济开发区",
    "370602000000": "芝罘区",
    "370611000000": "福山区",
    "370612000000": "牟平区",
    "370613000000": "莱山区",
    "370634000000": "长岛县",
    "370671000000": "烟台高新技术产业开发区",
    "370672000000": "烟台经济技术开发区",
    "370681000000": "龙口市",
    "370682000000": "莱阳市",
    "370683000000": "莱州市",
    "370684000000": "蓬莱市",
    "370685000000": "招远市",
    "370686000000": "栖霞市",
    "370687000000": "海阳市",
    "370702000000": "潍城区",
    "370703000000": "寒亭区",
    "370704000000": "坊子区",
    "370705000000": "奎文区",
    "370724000000": "临朐县",
    "370725000000": "昌乐县",
    "370772000000": "潍坊滨海经济技术开发区",
    "370781000000": "青州市",
    "370782000000": "诸城市",
    "370783000000": "寿光市",
    "370784000000": "安丘市",
    "370785000000": "高密市",
    "370786000000": "昌邑市",
    "370811000000": "任城区",
    "370812000000": "兖州区",
    "370826000000": "微山县",
    "370827000000": "鱼台县",
    "370828000000": "金乡县",
    "370829000000": "嘉祥县",
    "370830000000": "汶上县",
    "370831000000": "泗水县",
    "370832000000": "梁山县",
    "370871000000": "济宁高新技术产业开发区",
    "370881000000": "曲阜市",
    "370883000000": "邹城市",
    "370902000000": "泰山区",
    "370911000000": "岱岳区",
    "370921000000": "宁阳县",
    "370923000000": "东平县",
    "370982000000": "新泰市",
    "370983000000": "肥城市",
    "371002000000": "环翠区",
    "371003000000": "文登区",
    "371071000000": "威海火炬高技术产业开发区",
    "371072000000": "威海经济技术开发区",
    "371073000000": "威海临港经济技术开发区",
    "371082000000": "荣成市",
    "371083000000": "乳山市",
    "371102000000": "东港区",
    "371103000000": "岚山区",
    "371121000000": "五莲县",
    "371122000000": "莒县",
    "371171000000": "日照经济技术开发区",
    "371302000000": "兰山区",
    "371311000000": "罗庄区",
    "371312000000": "河东区",
    "371321000000": "沂南县",
    "371322000000": "郯城县",
    "371323000000": "沂水县",
    "371324000000": "兰陵县",
    "371325000000": "费县",
    "371326000000": "平邑县",
    "371327000000": "莒南县",
    "371328000000": "蒙阴县",
    "371329000000": "临沭县",
    "371371000000": "临沂高新技术产业开发区",
    "371372000000": "临沂经济技术开发区",
    "371373000000": "临沂临港经济开发区",
    "371402000000": "德城区",
    "371403000000": "陵城区",
    "371422000000": "宁津县",
    "371423000000": "庆云县",
    "371424000000": "临邑县",
    "371425000000": "齐河县",
    "371426000000": "平原县",
    "371427000000": "夏津县",
    "371428000000": "武城县",
    "371471000000": "德州经济技术开发区",
    "371472000000": "德州运河经济开发区",
    "371481000000": "乐陵市",
    "371482000000": "禹城市",
    "371502000000": "东昌府区",
    "371503000000": "茌平区",
    "371521000000": "阳谷县",
    "371522000000": "莘县",
    "371524000000": "东阿县",
    "371525000000": "冠县",
    "371526000000": "高唐县",
    "371581000000": "临清市",
    "371602000000": "滨城区",
    "371603000000": "沾化区",
    "371621000000": "惠民县",
    "371622000000": "阳信县",
    "371623000000": "无棣县",
    "371625000000": "博兴县",
    "371681000000": "邹平市",
    "371702000000": "牡丹区",
    "371703000000": "定陶区",
    "371721000000": "曹县",
    "371722000000": "单县",
    "371723000000": "成武县",
    "371724000000": "巨野县",
    "371725000000": "郓城县",
    "371726000000": "鄄城县",
    "371728000000": "东明县",
    "371771000000": "菏泽经济技术开发区",
    "371772000000": "菏泽高新技术开发区",
    "410102000000": "中原区",
    "410103000000": "二七区",
    "410104000000": "管城回族区",
    "410105000000": "金水区",
    "410106000000": "上街区",
    "410108000000": "惠济区",
    "410122000000": "中牟县",
    "410171000000": "郑州经济技术开发区",
    "410172000000": "郑州高新技术产业开发区",
    "410173000000": "郑州航空港经济综合实验区",
    "410181000000": "巩义市",
    "410182000000": "荥阳市",
    "410183000000": "新密市",
    "410184000000": "新郑市",
    "410185000000": "登封市",
    "410202000000": "龙亭区",
    "410203000000": "顺河回族区",
    "410204000000": "鼓楼区",
    "410205000000": "禹王台区",
    "410212000000": "祥符区",
    "410221000000": "杞县",
    "410222000000": "通许县",
    "410223000000": "尉氏县",
    "410225000000": "兰考县",
    "410302000000": "老城区",
    "410303000000": "西工区",
    "410304000000": "瀍河回族区",
    "410305000000": "涧西区",
    "410306000000": "吉利区",
    "410311000000": "洛龙区",
    "410322000000": "孟津县",
    "410323000000": "新安县",
    "410324000000": "栾川县",
    "410325000000": "嵩县",
    "410326000000": "汝阳县",
    "410327000000": "宜阳县",
    "410328000000": "洛宁县",
    "410329000000": "伊川县",
    "410371000000": "洛阳高新技术产业开发区",
    "410381000000": "偃师市",
    "410402000000": "新华区",
    "410403000000": "卫东区",
    "410404000000": "石龙区",
    "410411000000": "湛河区",
    "410421000000": "宝丰县",
    "410422000000": "叶县",
    "410423000000": "鲁山县",
    "410425000000": "郏县",
    "410471000000": "平顶山高新技术产业开发区",
    "410472000000": "平顶山市城乡一体化示范区",
    "410481000000": "舞钢市",
    "410482000000": "汝州市",
    "410502000000": "文峰区",
    "410503000000": "北关区",
    "410505000000": "殷都区",
    "410506000000": "龙安区",
    "410522000000": "安阳县",
    "410523000000": "汤阴县",
    "410526000000": "滑县",
    "410527000000": "内黄县",
    "410571000000": "安阳高新技术产业开发区",
    "410581000000": "林州市",
    "410602000000": "鹤山区",
    "410603000000": "山城区",
    "410611000000": "淇滨区",
    "410621000000": "浚县",
    "410622000000": "淇县",
    "410671000000": "鹤壁经济技术开发区",
    "410702000000": "红旗区",
    "410703000000": "卫滨区",
    "410704000000": "凤泉区",
    "410711000000": "牧野区",
    "410721000000": "新乡县",
    "410724000000": "获嘉县",
    "410725000000": "原阳县",
    "410726000000": "延津县",
    "410727000000": "封丘县",
    "410771000000": "新乡高新技术产业开发区",
    "410772000000": "新乡经济技术开发区",
    "410773000000": "新乡市平原城乡一体化示范区",
    "410781000000": "卫辉市",
    "410782000000": "辉县市",
    "410783000000": "长垣市",
    "410802000000": "解放区",
    "410803000000": "中站区",
    "410804000000": "马村区",
    "410811000000": "山阳区",
    "410821000000": "修武县",
    "410822000000": "博爱县",
    "410823000000": "武陟县",
    "410825000000": "温县",
    "410871000000": "焦作城乡一体化示范区",
    "410882000000": "沁阳市",
    "410883000000": "孟州市",
    "410902000000": "华龙区",
    "410922000000": "清丰县",
    "410923000000": "南乐县",
    "410926000000": "范县",
    "410927000000": "台前县",
    "410928000000": "濮阳县",
    "410971000000": "河南濮阳工业园区",
    "410972000000": "濮阳经济技术开发区",
    "411002000000": "魏都区",
    "411003000000": "建安区",
    "411024000000": "鄢陵县",
    "411025000000": "襄城县",
    "411071000000": "许昌经济技术开发区",
    "411081000000": "禹州市",
    "411082000000": "长葛市",
    "411102000000": "源汇区",
    "411103000000": "郾城区",
    "411104000000": "召陵区",
    "411121000000": "舞阳县",
    "411122000000": "临颍县",
    "411171000000": "漯河经济技术开发区",
    "411202000000": "湖滨区",
    "411203000000": "陕州区",
    "411221000000": "渑池县",
    "411224000000": "卢氏县",
    "411271000000": "河南三门峡经济开发区",
    "411281000000": "义马市",
    "411282000000": "灵宝市",
    "411302000000": "宛城区",
    "411303000000": "卧龙区",
    "411321000000": "南召县",
    "411322000000": "方城县",
    "411323000000": "西峡县",
    "411324000000": "镇平县",
    "411325000000": "内乡县",
    "411326000000": "淅川县",
    "411327000000": "社旗县",
    "411328000000": "唐河县",
    "411329000000": "新野县",
    "411330000000": "桐柏县",
    "411371000000": "南阳高新技术产业开发区",
    "411372000000": "南阳市城乡一体化示范区",
    "411381000000": "邓州市",
    "411402000000": "梁园区",
    "411403000000": "睢阳区",
    "411421000000": "民权县",
    "411422000000": "睢县",
    "411423000000": "宁陵县",
    "411424000000": "柘城县",
    "411425000000": "虞城县",
    "411426000000": "夏邑县",
    "411471000000": "豫东综合物流产业聚集区",
    "411472000000": "河南商丘经济开发区",
    "411481000000": "永城市",
    "411502000000": "浉河区",
    "411503000000": "平桥区",
    "411521000000": "罗山县",
    "411522000000": "光山县",
    "411523000000": "新县",
    "411524000000": "商城县",
    "411525000000": "固始县",
    "411526000000": "潢川县",
    "411527000000": "淮滨县",
    "411528000000": "息县",
    "411571000000": "信阳高新技术产业开发区",
    "411602000000": "川汇区",
    "411603000000": "淮阳区",
    "411621000000": "扶沟县",
    "411622000000": "西华县",
    "411623000000": "商水县",
    "411624000000": "沈丘县",
    "411625000000": "郸城县",
    "411627000000": "太康县",
    "411628000000": "鹿邑县",
    "411671000000": "河南周口经济开发区",
    "411681000000": "项城市",
    "411702000000": "驿城区",
    "411721000000": "西平县",
    "411722000000": "上蔡县",
    "411723000000": "平舆县",
    "411724000000": "正阳县",
    "411725000000": "确山县",
    "411726000000": "泌阳县",
    "411727000000": "汝南县",
    "411728000000": "遂平县",
    "411729000000": "新蔡县",
    "411771000000": "河南驻马店经济开发区",
    "419001000000": "济源市",
    "420102000000": "江岸区",
    "420103000000": "江汉区",
    "420104000000": "硚口区",
    "420105000000": "汉阳区",
    "420106000000": "武昌区",
    "420107000000": "青山区",
    "420111000000": "洪山区",
    "420112000000": "东西湖区",
    "420113000000": "汉南区",
    "420114000000": "蔡甸区",
    "420115000000": "江夏区",
    "420116000000": "黄陂区",
    "420117000000": "新洲区",
    "420202000000": "黄石港区",
    "420203000000": "西塞山区",
    "420204000000": "下陆区",
    "420205000000": "铁山区",
    "420222000000": "阳新县",
    "420281000000": "大冶市",
    "420302000000": "茅箭区",
    "420303000000": "张湾区",
    "420304000000": "郧阳区",
    "420322000000": "郧西县",
    "420323000000": "竹山县",
    "420324000000": "竹溪县",
    "420325000000": "房县",
    "420381000000": "丹江口市",
    "420502000000": "西陵区",
    "420503000000": "伍家岗区",
    "420504000000": "点军区",
    "420505000000": "猇亭区",
    "420506000000": "夷陵区",
    "420525000000": "远安县",
    "420526000000": "兴山县",
    "420527000000": "秭归县",
    "420528000000": "长阳土家族自治县",
    "420529000000": "五峰土家族自治县",
    "420581000000": "宜都市",
    "420582000000": "当阳市",
    "420583000000": "枝江市",
    "420602000000": "襄城区",
    "420606000000": "樊城区",
    "420607000000": "襄州区",
    "420624000000": "南漳县",
    "420625000000": "谷城县",
    "420626000000": "保康县",
    "420682000000": "老河口市",
    "420683000000": "枣阳市",
    "420684000000": "宜城市",
    "420702000000": "梁子湖区",
    "420703000000": "华容区",
    "420704000000": "鄂城区",
    "420802000000": "东宝区",
    "420804000000": "掇刀区",
    "420822000000": "沙洋县",
    "420881000000": "钟祥市",
    "420882000000": "京山市",
    "420902000000": "孝南区",
    "420921000000": "孝昌县",
    "420922000000": "大悟县",
    "420923000000": "云梦县",
    "420981000000": "应城市",
    "420982000000": "安陆市",
    "420984000000": "汉川市",
    "421002000000": "沙市区",
    "421003000000": "荆州区",
    "421022000000": "公安县",
    "421023000000": "监利县",
    "421024000000": "江陵县",
    "421071000000": "荆州经济技术开发区",
    "421081000000": "石首市",
    "421083000000": "洪湖市",
    "421087000000": "松滋市",
    "421102000000": "黄州区",
    "421121000000": "团风县",
    "421122000000": "红安县",
    "421123000000": "罗田县",
    "421124000000": "英山县",
    "421125000000": "浠水县",
    "421126000000": "蕲春县",
    "421127000000": "黄梅县",
    "421171000000": "龙感湖管理区",
    "421181000000": "麻城市",
    "421182000000": "武穴市",
    "421202000000": "咸安区",
    "421221000000": "嘉鱼县",
    "421222000000": "通城县",
    "421223000000": "崇阳县",
    "421224000000": "通山县",
    "421281000000": "赤壁市",
    "421303000000": "曾都区",
    "421321000000": "随县",
    "421381000000": "广水市",
    "422801000000": "恩施市",
    "422802000000": "利川市",
    "422822000000": "建始县",
    "422823000000": "巴东县",
    "422825000000": "宣恩县",
    "422826000000": "咸丰县",
    "422827000000": "来凤县",
    "422828000000": "鹤峰县",
    "429004000000": "仙桃市",
    "429005000000": "潜江市",
    "429006000000": "天门市",
    "429021000000": "神农架林区",
    "430102000000": "芙蓉区",
    "430103000000": "天心区",
    "430104000000": "岳麓区",
    "430105000000": "开福区",
    "430111000000": "雨花区",
    "430112000000": "望城区",
    "430121000000": "长沙县",
    "430181000000": "浏阳市",
    "430182000000": "宁乡市",
    "430202000000": "荷塘区",
    "430203000000": "芦淞区",
    "430204000000": "石峰区",
    "430211000000": "天元区",
    "430212000000": "渌口区",
    "430223000000": "攸县",
    "430224000000": "茶陵县",
    "430225000000": "炎陵县",
    "430271000000": "云龙示范区",
    "430281000000": "醴陵市",
    "430302000000": "雨湖区",
    "430304000000": "岳塘区",
    "430321000000": "湘潭县",
    "430371000000": "湖南湘潭高新技术产业园区",
    "430372000000": "湘潭昭山示范区",
    "430373000000": "湘潭九华示范区",
    "430381000000": "湘乡市",
    "430382000000": "韶山市",
    "430405000000": "珠晖区",
    "430406000000": "雁峰区",
    "430407000000": "石鼓区",
    "430408000000": "蒸湘区",
    "430412000000": "南岳区",
    "430421000000": "衡阳县",
    "430422000000": "衡南县",
    "430423000000": "衡山县",
    "430424000000": "衡东县",
    "430426000000": "祁东县",
    "430471000000": "衡阳综合保税区",
    "430472000000": "湖南衡阳高新技术产业园区",
    "430473000000": "湖南衡阳松木经济开发区",
    "430481000000": "耒阳市",
    "430482000000": "常宁市",
    "430502000000": "双清区",
    "430503000000": "大祥区",
    "430511000000": "北塔区",
    "430522000000": "新邵县",
    "430523000000": "邵阳县",
    "430524000000": "隆回县",
    "430525000000": "洞口县",
    "430527000000": "绥宁县",
    "430528000000": "新宁县",
    "430529000000": "城步苗族自治县",
    "430581000000": "武冈市",
    "430582000000": "邵东市",
    "430602000000": "岳阳楼区",
    "430603000000": "云溪区",
    "430611000000": "君山区",
    "430621000000": "岳阳县",
    "430623000000": "华容县",
    "430624000000": "湘阴县",
    "430626000000": "平江县",
    "430671000000": "岳阳市屈原管理区",
    "430681000000": "汨罗市",
    "430682000000": "临湘市",
    "430702000000": "武陵区",
    "430703000000": "鼎城区",
    "430721000000": "安乡县",
    "430722000000": "汉寿县",
    "430723000000": "澧县",
    "430724000000": "临澧县",
    "430725000000": "桃源县",
    "430726000000": "石门县",
    "430771000000": "常德市西洞庭管理区",
    "430781000000": "津市市",
    "430802000000": "永定区",
    "430811000000": "武陵源区",
    "430821000000": "慈利县",
    "430822000000": "桑植县",
    "430902000000": "资阳区",
    "430903000000": "赫山区",
    "430921000000": "南县",
    "430922000000": "桃江县",
    "430923000000": "安化县",
    "430971000000": "益阳市大通湖管理区",
    "430972000000": "湖南益阳高新技术产业园区",
    "430981000000": "沅江市",
    "431002000000": "北湖区",
    "431003000000": "苏仙区",
    "431021000000": "桂阳县",
    "431022000000": "宜章县",
    "431023000000": "永兴县",
    "431024000000": "嘉禾县",
    "431025000000": "临武县",
    "431026000000": "汝城县",
    "431027000000": "桂东县",
    "431028000000": "安仁县",
    "431081000000": "资兴市",
    "431102000000": "零陵区",
    "431103000000": "冷水滩区",
    "431121000000": "祁阳县",
    "431122000000": "东安县",
    "431123000000": "双牌县",
    "431124000000": "道县",
    "431125000000": "江永县",
    "431126000000": "宁远县",
    "431127000000": "蓝山县",
    "431128000000": "新田县",
    "431129000000": "江华瑶族自治县",
    "431171000000": "永州经济技术开发区",
    "431172000000": "永州市金洞管理区",
    "431173000000": "永州市回龙圩管理区",
    "431202000000": "鹤城区",
    "431221000000": "中方县",
    "431222000000": "沅陵县",
    "431223000000": "辰溪县",
    "431224000000": "溆浦县",
    "431225000000": "会同县",
    "431226000000": "麻阳苗族自治县",
    "431227000000": "新晃侗族自治县",
    "431228000000": "芷江侗族自治县",
    "431229000000": "靖州苗族侗族自治县",
    "431230000000": "通道侗族自治县",
    "431271000000": "怀化市洪江管理区",
    "431281000000": "洪江市",
    "431302000000": "娄星区",
    "431321000000": "双峰县",
    "431322000000": "新化县",
    "431381000000": "冷水江市",
    "431382000000": "涟源市",
    "433101000000": "吉首市",
    "433122000000": "泸溪县",
    "433123000000": "凤凰县",
    "433124000000": "花垣县",
    "433125000000": "保靖县",
    "433126000000": "古丈县",
    "433127000000": "永顺县",
    "433130000000": "龙山县",
    "433173000000": "湖南永顺经济开发区",
    "440103000000": "荔湾区",
    "440104000000": "越秀区",
    "440105000000": "海珠区",
    "440106000000": "天河区",
    "440111000000": "白云区",
    "440112000000": "黄埔区",
    "440113000000": "番禺区",
    "440114000000": "花都区",
    "440115000000": "南沙区",
    "440117000000": "从化区",
    "440118000000": "增城区",
    "440203000000": "武江区",
    "440204000000": "浈江区",
    "440205000000": "曲江区",
    "440222000000": "始兴县",
    "440224000000": "仁化县",
    "440229000000": "翁源县",
    "440232000000": "乳源瑶族自治县",
    "440233000000": "新丰县",
    "440281000000": "乐昌市",
    "440282000000": "南雄市",
    "440303000000": "罗湖区",
    "440304000000": "福田区",
    "440305000000": "南山区",
    "440306000000": "宝安区",
    "440307000000": "龙岗区",
    "440308000000": "盐田区",
    "440309000000": "龙华区",
    "440310000000": "坪山区",
    "440311000000": "光明区",
    "440402000000": "香洲区",
    "440403000000": "斗门区",
    "440404000000": "金湾区",
    "440507000000": "龙湖区",
    "440511000000": "金平区",
    "440512000000": "濠江区",
    "440513000000": "潮阳区",
    "440514000000": "潮南区",
    "440515000000": "澄海区",
    "440523000000": "南澳县",
    "440604000000": "禅城区",
    "440605000000": "南海区",
    "440606000000": "顺德区",
    "440607000000": "三水区",
    "440608000000": "高明区",
    "440703000000": "蓬江区",
    "440704000000": "江海区",
    "440705000000": "新会区",
    "440781000000": "台山市",
    "440783000000": "开平市",
    "440784000000": "鹤山市",
    "440785000000": "恩平市",
    "440802000000": "赤坎区",
    "440803000000": "霞山区",
    "440804000000": "坡头区",
    "440811000000": "麻章区",
    "440823000000": "遂溪县",
    "440825000000": "徐闻县",
    "440881000000": "廉江市",
    "440882000000": "雷州市",
    "440883000000": "吴川市",
    "440902000000": "茂南区",
    "440904000000": "电白区",
    "440981000000": "高州市",
    "440982000000": "化州市",
    "440983000000": "信宜市",
    "441202000000": "端州区",
    "441203000000": "鼎湖区",
    "441204000000": "高要区",
    "441223000000": "广宁县",
    "441224000000": "怀集县",
    "441225000000": "封开县",
    "441226000000": "德庆县",
    "441284000000": "四会市",
    "441302000000": "惠城区",
    "441303000000": "惠阳区",
    "441322000000": "博罗县",
    "441323000000": "惠东县",
    "441324000000": "龙门县",
    "441402000000": "梅江区",
    "441403000000": "梅县区",
    "441422000000": "大埔县",
    "441423000000": "丰顺县",
    "441424000000": "五华县",
    "441426000000": "平远县",
    "441427000000": "蕉岭县",
    "441481000000": "兴宁市",
    "441502000000": "城区",
    "441521000000": "海丰县",
    "441523000000": "陆河县",
    "441581000000": "陆丰市",
    "441602000000": "源城区",
    "441621000000": "紫金县",
    "441622000000": "龙川县",
    "441623000000": "连平县",
    "441624000000": "和平县",
    "441625000000": "东源县",
    "441702000000": "江城区",
    "441704000000": "阳东区",
    "441721000000": "阳西县",
    "441781000000": "阳春市",
    "441802000000": "清城区",
    "441803000000": "清新区",
    "441821000000": "佛冈县",
    "441823000000": "阳山县",
    "441825000000": "连山壮族瑶族自治县",
    "441826000000": "连南瑶族自治县",
    "441881000000": "英德市",
    "441882000000": "连州市",
    "445102000000": "湘桥区",
    "445103000000": "潮安区",
    "445122000000": "饶平县",
    "445202000000": "榕城区",
    "445203000000": "揭东区",
    "445222000000": "揭西县",
    "445224000000": "惠来县",
    "445281000000": "普宁市",
    "445302000000": "云城区",
    "445303000000": "云安区",
    "445321000000": "新兴县",
    "445322000000": "郁南县",
    "445381000000": "罗定市",
    "450102000000": "兴宁区",
    "450103000000": "青秀区",
    "450105000000": "江南区",
    "450107000000": "西乡塘区",
    "450108000000": "良庆区",
    "450109000000": "邕宁区",
    "450110000000": "武鸣区",
    "450123000000": "隆安县",
    "450124000000": "马山县",
    "450125000000": "上林县",
    "450126000000": "宾阳县",
    "450127000000": "横县",
    "450202000000": "城中区",
    "450203000000": "鱼峰区",
    "450204000000": "柳南区",
    "450205000000": "柳北区",
    "450206000000": "柳江区",
    "450222000000": "柳城县",
    "450223000000": "鹿寨县",
    "450224000000": "融安县",
    "450225000000": "融水苗族自治县",
    "450226000000": "三江侗族自治县",
    "450302000000": "秀峰区",
    "450303000000": "叠彩区",
    "450304000000": "象山区",
    "450305000000": "七星区",
    "450311000000": "雁山区",
    "450312000000": "临桂区",
    "450321000000": "阳朔县",
    "450323000000": "灵川县",
    "450324000000": "全州县",
    "450325000000": "兴安县",
    "450326000000": "永福县",
    "450327000000": "灌阳县",
    "450328000000": "龙胜各族自治县",
    "450329000000": "资源县",
    "450330000000": "平乐县",
    "450332000000": "恭城瑶族自治县",
    "450381000000": "荔浦市",
    "450403000000": "万秀区",
    "450405000000": "长洲区",
    "450406000000": "龙圩区",
    "450421000000": "苍梧县",
    "450422000000": "藤县",
    "450423000000": "蒙山县",
    "450481000000": "岑溪市",
    "450502000000": "海城区",
    "450503000000": "银海区",
    "450512000000": "铁山港区",
    "450521000000": "合浦县",
    "450602000000": "港口区",
    "450603000000": "防城区",
    "450621000000": "上思县",
    "450681000000": "东兴市",
    "450702000000": "钦南区",
    "450703000000": "钦北区",
    "450721000000": "灵山县",
    "450722000000": "浦北县",
    "450802000000": "港北区",
    "450803000000": "港南区",
    "450804000000": "覃塘区",
    "450821000000": "平南县",
    "450881000000": "桂平市",
    "450902000000": "玉州区",
    "450903000000": "福绵区",
    "450921000000": "容县",
    "450922000000": "陆川县",
    "450923000000": "博白县",
    "450924000000": "兴业县",
    "450981000000": "北流市",
    "451002000000": "右江区",
    "451003000000": "田阳区",
    "451022000000": "田东县",
    "451023000000": "平果县",
    "451024000000": "德保县",
    "451026000000": "那坡县",
    "451027000000": "凌云县",
    "451028000000": "乐业县",
    "451029000000": "田林县",
    "451030000000": "西林县",
    "451031000000": "隆林各族自治县",
    "451081000000": "靖西市",
    "451102000000": "八步区",
    "451103000000": "平桂区",
    "451121000000": "昭平县",
    "451122000000": "钟山县",
    "451123000000": "富川瑶族自治县",
    "451202000000": "金城江区",
    "451203000000": "宜州区",
    "451221000000": "南丹县",
    "451222000000": "天峨县",
    "451223000000": "凤山县",
    "451224000000": "东兰县",
    "451225000000": "罗城仫佬族自治县",
    "451226000000": "环江毛南族自治县",
    "451227000000": "巴马瑶族自治县",
    "451228000000": "都安瑶族自治县",
    "451229000000": "大化瑶族自治县",
    "451302000000": "兴宾区",
    "451321000000": "忻城县",
    "451322000000": "象州县",
    "451323000000": "武宣县",
    "451324000000": "金秀瑶族自治县",
    "451381000000": "合山市",
    "451402000000": "江州区",
    "451421000000": "扶绥县",
    "451422000000": "宁明县",
    "451423000000": "龙州县",
    "451424000000": "大新县",
    "451425000000": "天等县",
    "451481000000": "凭祥市",
    "460105000000": "秀英区",
    "460106000000": "龙华区",
    "460107000000": "琼山区",
    "460108000000": "美兰区",
    "460202000000": "海棠区",
    "460203000000": "吉阳区",
    "460204000000": "天涯区",
    "460205000000": "崖州区",
    "460321000000": "西沙群岛",
    "460322000000": "南沙群岛",
    "460323000000": "中沙群岛的岛礁及其海域",
    "469001000000": "五指山市",
    "469002000000": "琼海市",
    "469005000000": "文昌市",
    "469006000000": "万宁市",
    "469007000000": "东方市",
    "469021000000": "定安县",
    "469022000000": "屯昌县",
    "469023000000": "澄迈县",
    "469024000000": "临高县",
    "469025000000": "白沙黎族自治县",
    "469026000000": "昌江黎族自治县",
    "469027000000": "乐东黎族自治县",
    "469028000000": "陵水黎族自治县",
    "469029000000": "保亭黎族苗族自治县",
    "469030000000": "琼中黎族苗族自治县",
    "500101000000": "万州区",
    "500102000000": "涪陵区",
    "500103000000": "渝中区",
    "500104000000": "大渡口区",
    "500105000000": "江北区",
    "500106000000": "沙坪坝区",
    "500107000000": "九龙坡区",
    "500108000000": "南岸区",
    "500109000000": "北碚区",
    "500110000000": "綦江区",
    "500111000000": "大足区",
    "500112000000": "渝北区",
    "500113000000": "巴南区",
    "500114000000": "黔江区",
    "500115000000": "长寿区",
    "500116000000": "江津区",
    "500117000000": "合川区",
    "500118000000": "永川区",
    "500119000000": "南川区",
    "500120000000": "璧山区",
    "500151000000": "铜梁区",
    "500152000000": "潼南区",
    "500153000000": "荣昌区",
    "500154000000": "开州区",
    "500155000000": "梁平区",
    "500156000000": "武隆区",
    "500229000000": "城口县",
    "500230000000": "丰都县",
    "500231000000": "垫江县",
    "500233000000": "忠县",
    "500235000000": "云阳县",
    "500236000000": "奉节县",
    "500237000000": "巫山县",
    "500238000000": "巫溪县",
    "500240000000": "石柱土家族自治县",
    "500241000000": "秀山土家族苗族自治县",
    "500242000000": "酉阳土家族苗族自治县",
    "500243000000": "彭水苗族土家族自治县",
    "510104000000": "锦江区",
    "510105000000": "青羊区",
    "510106000000": "金牛区",
    "510107000000": "武侯区",
    "510108000000": "成华区",
    "510112000000": "龙泉驿区",
    "510113000000": "青白江区",
    "510114000000": "新都区",
    "510115000000": "温江区",
    "510116000000": "双流区",
    "510117000000": "郫都区",
    "510121000000": "金堂县",
    "510129000000": "大邑县",
    "510131000000": "蒲江县",
    "510132000000": "新津县",
    "510181000000": "都江堰市",
    "510182000000": "彭州市",
    "510183000000": "邛崃市",
    "510184000000": "崇州市",
    "510185000000": "简阳市",
    "510302000000": "自流井区",
    "510303000000": "贡井区",
    "510304000000": "大安区",
    "510311000000": "沿滩区",
    "510321000000": "荣县",
    "510322000000": "富顺县",
    "510402000000": "东区",
    "510403000000": "西区",
    "510411000000": "仁和区",
    "510421000000": "米易县",
    "510422000000": "盐边县",
    "510502000000": "江阳区",
    "510503000000": "纳溪区",
    "510504000000": "龙马潭区",
    "510521000000": "泸县",
    "510522000000": "合江县",
    "510524000000": "叙永县",
    "510525000000": "古蔺县",
    "510603000000": "旌阳区",
    "510604000000": "罗江区",
    "510623000000": "中江县",
    "510681000000": "广汉市",
    "510682000000": "什邡市",
    "510683000000": "绵竹市",
    "510703000000": "涪城区",
    "510704000000": "游仙区",
    "510705000000": "安州区",
    "510722000000": "三台县",
    "510723000000": "盐亭县",
    "510725000000": "梓潼县",
    "510726000000": "北川羌族自治县",
    "510727000000": "平武县",
    "510781000000": "江油市",
    "510802000000": "利州区",
    "510811000000": "昭化区",
    "510812000000": "朝天区",
    "510821000000": "旺苍县",
    "510822000000": "青川县",
    "510823000000": "剑阁县",
    "510824000000": "苍溪县",
    "510903000000": "船山区",
    "510904000000": "安居区",
    "510921000000": "蓬溪县",
    "510923000000": "大英县",
    "510981000000": "射洪市",
    "511002000000": "市中区",
    "511011000000": "东兴区",
    "511024000000": "威远县",
    "511025000000": "资中县",
    "511071000000": "内江经济开发区",
    "511083000000": "隆昌市",
    "511102000000": "市中区",
    "511111000000": "沙湾区",
    "511112000000": "五通桥区",
    "511113000000": "金口河区",
    "511123000000": "犍为县",
    "511124000000": "井研县",
    "511126000000": "夹江县",
    "511129000000": "沐川县",
    "511132000000": "峨边彝族自治县",
    "511133000000": "马边彝族自治县",
    "511181000000": "峨眉山市",
    "511302000000": "顺庆区",
    "511303000000": "高坪区",
    "511304000000": "嘉陵区",
    "511321000000": "南部县",
    "511322000000": "营山县",
    "511323000000": "蓬安县",
    "511324000000": "仪陇县",
    "511325000000": "西充县",
    "511381000000": "阆中市",
    "511402000000": "东坡区",
    "511403000000": "彭山区",
    "511421000000": "仁寿县",
    "511423000000": "洪雅县",
    "511424000000": "丹棱县",
    "511425000000": "青神县",
    "511502000000": "翠屏区",
    "511503000000": "南溪区",
    "511504000000": "叙州区",
    "511523000000": "江安县",
    "511524000000": "长宁县",
    "511525000000": "高县",
    "511526000000": "珙县",
    "511527000000": "筠连县",
    "511528000000": "兴文县",
    "511529000000": "屏山县",
    "511602000000": "广安区",
    "511603000000": "前锋区",
    "511621000000": "岳池县",
    "511622000000": "武胜县",
    "511623000000": "邻水县",
    "511681000000": "华蓥市",
    "511702000000": "通川区",
    "511703000000": "达川区",
    "511722000000": "宣汉县",
    "511723000000": "开江县",
    "511724000000": "大竹县",
    "511725000000": "渠县",
    "511771000000": "达州经济开发区",
    "511781000000": "万源市",
    "511802000000": "雨城区",
    "511803000000": "名山区",
    "511822000000": "荥经县",
    "511823000000": "汉源县",
    "511824000000": "石棉县",
    "511825000000": "天全县",
    "511826000000": "芦山县",
    "511827000000": "宝兴县",
    "511902000000": "巴州区",
    "511903000000": "恩阳区",
    "511921000000": "通江县",
    "511922000000": "南江县",
    "511923000000": "平昌县",
    "511971000000": "巴中经济开发区",
    "512002000000": "雁江区",
    "512021000000": "安岳县",
    "512022000000": "乐至县",
    "513201000000": "马尔康市",
    "513221000000": "汶川县",
    "513222000000": "理县",
    "513223000000": "茂县",
    "513224000000": "松潘县",
    "513225000000": "九寨沟县",
    "513226000000": "金川县",
    "513227000000": "小金县",
    "513228000000": "黑水县",
    "513230000000": "壤塘县",
    "513231000000": "阿坝县",
    "513232000000": "若尔盖县",
    "513233000000": "红原县",
    "513301000000": "康定市",
    "513322000000": "泸定县",
    "513323000000": "丹巴县",
    "513324000000": "九龙县",
    "513325000000": "雅江县",
    "513326000000": "道孚县",
    "513327000000": "炉霍县",
    "513328000000": "甘孜县",
    "513329000000": "新龙县",
    "513330000000": "德格县",
    "513331000000": "白玉县",
    "513332000000": "石渠县",
    "513333000000": "色达县",
    "513334000000": "理塘县",
    "513335000000": "巴塘县",
    "513336000000": "乡城县",
    "513337000000": "稻城县",
    "513338000000": "得荣县",
    "513401000000": "西昌市",
    "513422000000": "木里藏族自治县",
    "513423000000": "盐源县",
    "513424000000": "德昌县",
    "513425000000": "会理县",
    "513426000000": "会东县",
    "513427000000": "宁南县",
    "513428000000": "普格县",
    "513429000000": "布拖县",
    "513430000000": "金阳县",
    "513431000000": "昭觉县",
    "513432000000": "喜德县",
    "513433000000": "冕宁县",
    "513434000000": "越西县",
    "513435000000": "甘洛县",
    "513436000000": "美姑县",
    "513437000000": "雷波县",
    "520102000000": "南明区",
    "520103000000": "云岩区",
    "520111000000": "花溪区",
    "520112000000": "乌当区",
    "520113000000": "白云区",
    "520115000000": "观山湖区",
    "520121000000": "开阳县",
    "520122000000": "息烽县",
    "520123000000": "修文县",
    "520181000000": "清镇市",
    "520201000000": "钟山区",
    "520203000000": "六枝特区",
    "520221000000": "水城县",
    "520281000000": "盘州市",
    "520302000000": "红花岗区",
    "520303000000": "汇川区",
    "520304000000": "播州区",
    "520322000000": "桐梓县",
    "520323000000": "绥阳县",
    "520324000000": "正安县",
    "520325000000": "道真仡佬族苗族自治县",
    "520326000000": "务川仡佬族苗族自治县",
    "520327000000": "凤冈县",
    "520328000000": "湄潭县",
    "520329000000": "余庆县",
    "520330000000": "习水县",
    "520381000000": "赤水市",
    "520382000000": "仁怀市",
    "520402000000": "西秀区",
    "520403000000": "平坝区",
    "520422000000": "普定县",
    "520423000000": "镇宁布依族苗族自治县",
    "520424000000": "关岭布依族苗族自治县",
    "520425000000": "紫云苗族布依族自治县",
    "520502000000": "七星关区",
    "520521000000": "大方县",
    "520522000000": "黔西县",
    "520523000000": "金沙县",
    "520524000000": "织金县",
    "520525000000": "纳雍县",
    "520526000000": "威宁彝族回族苗族自治县",
    "520527000000": "赫章县",
    "520602000000": "碧江区",
    "520603000000": "万山区",
    "520621000000": "江口县",
    "520622000000": "玉屏侗族自治县",
    "520623000000": "石阡县",
    "520624000000": "思南县",
    "520625000000": "印江土家族苗族自治县",
    "520626000000": "德江县",
    "520627000000": "沿河土家族自治县",
    "520628000000": "松桃苗族自治县",
    "522301000000": "兴义市",
    "522302000000": "兴仁市",
    "522323000000": "普安县",
    "522324000000": "晴隆县",
    "522325000000": "贞丰县",
    "522326000000": "望谟县",
    "522327000000": "册亨县",
    "522328000000": "安龙县",
    "522601000000": "凯里市",
    "522622000000": "黄平县",
    "522623000000": "施秉县",
    "522624000000": "三穗县",
    "522625000000": "镇远县",
    "522626000000": "岑巩县",
    "522627000000": "天柱县",
    "522628000000": "锦屏县",
    "522629000000": "剑河县",
    "522630000000": "台江县",
    "522631000000": "黎平县",
    "522632000000": "榕江县",
    "522633000000": "从江县",
    "522634000000": "雷山县",
    "522635000000": "麻江县",
    "522636000000": "丹寨县",
    "522701000000": "都匀市",
    "522702000000": "福泉市",
    "522722000000": "荔波县",
    "522723000000": "贵定县",
    "522725000000": "瓮安县",
    "522726000000": "独山县",
    "522727000000": "平塘县",
    "522728000000": "罗甸县",
    "522729000000": "长顺县",
    "522730000000": "龙里县",
    "522731000000": "惠水县",
    "522732000000": "三都水族自治县",
    "530102000000": "五华区",
    "530103000000": "盘龙区",
    "530111000000": "官渡区",
    "530112000000": "西山区",
    "530113000000": "东川区",
    "530114000000": "呈贡区",
    "530115000000": "晋宁区",
    "530124000000": "富民县",
    "530125000000": "宜良县",
    "530126000000": "石林彝族自治县",
    "530127000000": "嵩明县",
    "530128000000": "禄劝彝族苗族自治县",
    "530129000000": "寻甸回族彝族自治县",
    "530181000000": "安宁市",
    "530302000000": "麒麟区",
    "530303000000": "沾益区",
    "530304000000": "马龙区",
    "530322000000": "陆良县",
    "530323000000": "师宗县",
    "530324000000": "罗平县",
    "530325000000": "富源县",
    "530326000000": "会泽县",
    "530381000000": "宣威市",
    "530402000000": "红塔区",
    "530403000000": "江川区",
    "530422000000": "澄江县",
    "530423000000": "通海县",
    "530424000000": "华宁县",
    "530425000000": "易门县",
    "530426000000": "峨山彝族自治县",
    "530427000000": "新平彝族傣族自治县",
    "530428000000": "元江哈尼族彝族傣族自治县",
    "530502000000": "隆阳区",
    "530521000000": "施甸县",
    "530523000000": "龙陵县",
    "530524000000": "昌宁县",
    "530581000000": "腾冲市",
    "530602000000": "昭阳区",
    "530621000000": "鲁甸县",
    "530622000000": "巧家县",
    "530623000000": "盐津县",
    "530624000000": "大关县",
    "530625000000": "永善县",
    "530626000000": "绥江县",
    "530627000000": "镇雄县",
    "530628000000": "彝良县",
    "530629000000": "威信县",
    "530681000000": "水富市",
    "530702000000": "古城区",
    "530721000000": "玉龙纳西族自治县",
    "530722000000": "永胜县",
    "530723000000": "华坪县",
    "530724000000": "宁蒗彝族自治县",
    "530802000000": "思茅区",
    "530821000000": "宁洱哈尼族彝族自治县",
    "530822000000": "墨江哈尼族自治县",
    "530823000000": "景东彝族自治县",
    "530824000000": "景谷傣族彝族自治县",
    "530825000000": "镇沅彝族哈尼族拉祜族自治县",
    "530826000000": "江城哈尼族彝族自治县",
    "530827000000": "孟连傣族拉祜族佤族自治县",
    "530828000000": "澜沧拉祜族自治县",
    "530829000000": "西盟佤族自治县",
    "530902000000": "临翔区",
    "530921000000": "凤庆县",
    "530922000000": "云县",
    "530923000000": "永德县",
    "530924000000": "镇康县",
    "530925000000": "双江拉祜族佤族布朗族傣族自治县",
    "530926000000": "耿马傣族佤族自治县",
    "530927000000": "沧源佤族自治县",
    "532301000000": "楚雄市",
    "532322000000": "双柏县",
    "532323000000": "牟定县",
    "532324000000": "南华县",
    "532325000000": "姚安县",
    "532326000000": "大姚县",
    "532327000000": "永仁县",
    "532328000000": "元谋县",
    "532329000000": "武定县",
    "532331000000": "禄丰县",
    "532501000000": "个旧市",
    "532502000000": "开远市",
    "532503000000": "蒙自市",
    "532504000000": "弥勒市",
    "532523000000": "屏边苗族自治县",
    "532524000000": "建水县",
    "532525000000": "石屏县",
    "532527000000": "泸西县",
    "532528000000": "元阳县",
    "532529000000": "红河县",
    "532530000000": "金平苗族瑶族傣族自治县",
    "532531000000": "绿春县",
    "532532000000": "河口瑶族自治县",
    "532601000000": "文山市",
    "532622000000": "砚山县",
    "532623000000": "西畴县",
    "532624000000": "麻栗坡县",
    "532625000000": "马关县",
    "532626000000": "丘北县",
    "532627000000": "广南县",
    "532628000000": "富宁县",
    "532801000000": "景洪市",
    "532822000000": "勐海县",
    "532823000000": "勐腊县",
    "532901000000": "大理市",
    "532922000000": "漾濞彝族自治县",
    "532923000000": "祥云县",
    "532924000000": "宾川县",
    "532925000000": "弥渡县",
    "532926000000": "南涧彝族自治县",
    "532927000000": "巍山彝族回族自治县",
    "532928000000": "永平县",
    "532929000000": "云龙县",
    "532930000000": "洱源县",
    "532931000000": "剑川县",
    "532932000000": "鹤庆县",
    "533102000000": "瑞丽市",
    "533103000000": "芒市",
    "533122000000": "梁河县",
    "533123000000": "盈江县",
    "533124000000": "陇川县",
    "533301000000": "泸水市",
    "533323000000": "福贡县",
    "533324000000": "贡山独龙族怒族自治县",
    "533325000000": "兰坪白族普米族自治县",
    "533401000000": "香格里拉市",
    "533422000000": "德钦县",
    "533423000000": "维西傈僳族自治县",
    "540102000000": "城关区",
    "540103000000": "堆龙德庆区",
    "540104000000": "达孜区",
    "540121000000": "林周县",
    "540122000000": "当雄县",
    "540123000000": "尼木县",
    "540124000000": "曲水县",
    "540127000000": "墨竹工卡县",
    "540171000000": "格尔木藏青工业园区",
    "540172000000": "拉萨经济技术开发区",
    "540173000000": "西藏文化旅游创意园区",
    "540174000000": "达孜工业园区",
    "540202000000": "桑珠孜区",
    "540221000000": "南木林县",
    "540222000000": "江孜县",
    "540223000000": "定日县",
    "540224000000": "萨迦县",
    "540225000000": "拉孜县",
    "540226000000": "昂仁县",
    "540227000000": "谢通门县",
    "540228000000": "白朗县",
    "540229000000": "仁布县",
    "540230000000": "康马县",
    "540231000000": "定结县",
    "540232000000": "仲巴县",
    "540233000000": "亚东县",
    "540234000000": "吉隆县",
    "540235000000": "聂拉木县",
    "540236000000": "萨嘎县",
    "540237000000": "岗巴县",
    "540302000000": "卡若区",
    "540321000000": "江达县",
    "540322000000": "贡觉县",
    "540323000000": "类乌齐县",
    "540324000000": "丁青县",
    "540325000000": "察雅县",
    "540326000000": "八宿县",
    "540327000000": "左贡县",
    "540328000000": "芒康县",
    "540329000000": "洛隆县",
    "540330000000": "边坝县",
    "540402000000": "巴宜区",
    "540421000000": "工布江达县",
    "540422000000": "米林县",
    "540423000000": "墨脱县",
    "540424000000": "波密县",
    "540425000000": "察隅县",
    "540426000000": "朗县",
    "540502000000": "乃东区",
    "540521000000": "扎囊县",
    "540522000000": "贡嘎县",
    "540523000000": "桑日县",
    "540524000000": "琼结县",
    "540525000000": "曲松县",
    "540526000000": "措美县",
    "540527000000": "洛扎县",
    "540528000000": "加查县",
    "540529000000": "隆子县",
    "540530000000": "错那县",
    "540531000000": "浪卡子县",
    "540602000000": "色尼区",
    "540621000000": "嘉黎县",
    "540622000000": "比如县",
    "540623000000": "聂荣县",
    "540624000000": "安多县",
    "540625000000": "申扎县",
    "540626000000": "索县",
    "540627000000": "班戈县",
    "540628000000": "巴青县",
    "540629000000": "尼玛县",
    "540630000000": "双湖县",
    "542521000000": "普兰县",
    "542522000000": "札达县",
    "542523000000": "噶尔县",
    "542524000000": "日土县",
    "542525000000": "革吉县",
    "542526000000": "改则县",
    "542527000000": "措勤县",
    "610102000000": "新城区",
    "610103000000": "碑林区",
    "610104000000": "莲湖区",
    "610111000000": "灞桥区",
    "610112000000": "未央区",
    "610113000000": "雁塔区",
    "610114000000": "阎良区",
    "610115000000": "临潼区",
    "610116000000": "长安区",
    "610117000000": "高陵区",
    "610118000000": "鄠邑区",
    "610122000000": "蓝田县",
    "610124000000": "周至县",
    "610202000000": "王益区",
    "610203000000": "印台区",
    "610204000000": "耀州区",
    "610222000000": "宜君县",
    "610302000000": "渭滨区",
    "610303000000": "金台区",
    "610304000000": "陈仓区",
    "610322000000": "凤翔县",
    "610323000000": "岐山县",
    "610324000000": "扶风县",
    "610326000000": "眉县",
    "610327000000": "陇县",
    "610328000000": "千阳县",
    "610329000000": "麟游县",
    "610330000000": "凤县",
    "610331000000": "太白县",
    "610402000000": "秦都区",
    "610403000000": "杨陵区",
    "610404000000": "渭城区",
    "610422000000": "三原县",
    "610423000000": "泾阳县",
    "610424000000": "乾县",
    "610425000000": "礼泉县",
    "610426000000": "永寿县",
    "610428000000": "长武县",
    "610429000000": "旬邑县",
    "610430000000": "淳化县",
    "610431000000": "武功县",
    "610481000000": "兴平市",
    "610482000000": "彬州市",
    "610502000000": "临渭区",
    "610503000000": "华州区",
    "610522000000": "潼关县",
    "610523000000": "大荔县",
    "610524000000": "合阳县",
    "610525000000": "澄城县",
    "610526000000": "蒲城县",
    "610527000000": "白水县",
    "610528000000": "富平县",
    "610581000000": "韩城市",
    "610582000000": "华阴市",
    "610602000000": "宝塔区",
    "610603000000": "安塞区",
    "610621000000": "延长县",
    "610622000000": "延川县",
    "610625000000": "志丹县",
    "610626000000": "吴起县",
    "610627000000": "甘泉县",
    "610628000000": "富县",
    "610629000000": "洛川县",
    "610630000000": "宜川县",
    "610631000000": "黄龙县",
    "610632000000": "黄陵县",
    "610681000000": "子长市",
    "610702000000": "汉台区",
    "610703000000": "南郑区",
    "610722000000": "城固县",
    "610723000000": "洋县",
    "610724000000": "西乡县",
    "610725000000": "勉县",
    "610726000000": "宁强县",
    "610727000000": "略阳县",
    "610728000000": "镇巴县",
    "610729000000": "留坝县",
    "610730000000": "佛坪县",
    "610802000000": "榆阳区",
    "610803000000": "横山区",
    "610822000000": "府谷县",
    "610824000000": "靖边县",
    "610825000000": "定边县",
    "610826000000": "绥德县",
    "610827000000": "米脂县",
    "610828000000": "佳县",
    "610829000000": "吴堡县",
    "610830000000": "清涧县",
    "610831000000": "子洲县",
    "610881000000": "神木市",
    "610902000000": "汉滨区",
    "610921000000": "汉阴县",
    "610922000000": "石泉县",
    "610923000000": "宁陕县",
    "610924000000": "紫阳县",
    "610925000000": "岚皋县",
    "610926000000": "平利县",
    "610927000000": "镇坪县",
    "610928000000": "旬阳县",
    "610929000000": "白河县",
    "611002000000": "商州区",
    "611021000000": "洛南县",
    "611022000000": "丹凤县",
    "611023000000": "商南县",
    "611024000000": "山阳县",
    "611025000000": "镇安县",
    "611026000000": "柞水县",
    "620102000000": "城关区",
    "620103000000": "七里河区",
    "620104000000": "西固区",
    "620105000000": "安宁区",
    "620111000000": "红古区",
    "620121000000": "永登县",
    "620122000000": "皋兰县",
    "620123000000": "榆中县",
    "620171000000": "兰州新区",
    "620201000000": "市辖区",
    "620302000000": "金川区",
    "620321000000": "永昌县",
    "620402000000": "白银区",
    "620403000000": "平川区",
    "620421000000": "靖远县",
    "620422000000": "会宁县",
    "620423000000": "景泰县",
    "620502000000": "秦州区",
    "620503000000": "麦积区",
    "620521000000": "清水县",
    "620522000000": "秦安县",
    "620523000000": "甘谷县",
    "620524000000": "武山县",
    "620525000000": "张家川回族自治县",
    "620602000000": "凉州区",
    "620621000000": "民勤县",
    "620622000000": "古浪县",
    "620623000000": "天祝藏族自治县",
    "620702000000": "甘州区",
    "620721000000": "肃南裕固族自治县",
    "620722000000": "民乐县",
    "620723000000": "临泽县",
    "620724000000": "高台县",
    "620725000000": "山丹县",
    "620802000000": "崆峒区",
    "620821000000": "泾川县",
    "620822000000": "灵台县",
    "620823000000": "崇信县",
    "620825000000": "庄浪县",
    "620826000000": "静宁县",
    "620881000000": "华亭市",
    "620902000000": "肃州区",
    "620921000000": "金塔县",
    "620922000000": "瓜州县",
    "620923000000": "肃北蒙古族自治县",
    "620924000000": "阿克塞哈萨克族自治县",
    "620981000000": "玉门市",
    "620982000000": "敦煌市",
    "621002000000": "西峰区",
    "621021000000": "庆城县",
    "621022000000": "环县",
    "621023000000": "华池县",
    "621024000000": "合水县",
    "621025000000": "正宁县",
    "621026000000": "宁县",
    "621027000000": "镇原县",
    "621102000000": "安定区",
    "621121000000": "通渭县",
    "621122000000": "陇西县",
    "621123000000": "渭源县",
    "621124000000": "临洮县",
    "621125000000": "漳县",
    "621126000000": "岷县",
    "621202000000": "武都区",
    "621221000000": "成县",
    "621222000000": "文县",
    "621223000000": "宕昌县",
    "621224000000": "康县",
    "621225000000": "西和县",
    "621226000000": "礼县",
    "621227000000": "徽县",
    "621228000000": "两当县",
    "622901000000": "临夏市",
    "622921000000": "临夏县",
    "622922000000": "康乐县",
    "622923000000": "永靖县",
    "622924000000": "广河县",
    "622925000000": "和政县",
    "622926000000": "东乡族自治县",
    "622927000000": "积石山保安族东乡族撒拉族自治县",
    "623001000000": "合作市",
    "623021000000": "临潭县",
    "623022000000": "卓尼县",
    "623023000000": "舟曲县",
    "623024000000": "迭部县",
    "623025000000": "玛曲县",
    "623026000000": "碌曲县",
    "623027000000": "夏河县",
    "630102000000": "城东区",
    "630103000000": "城中区",
    "630104000000": "城西区",
    "630105000000": "城北区",
    "630121000000": "大通回族土族自治县",
    "630122000000": "湟中县",
    "630123000000": "湟源县",
    "630202000000": "乐都区",
    "630203000000": "平安区",
    "630222000000": "民和回族土族自治县",
    "630223000000": "互助土族自治县",
    "630224000000": "化隆回族自治县",
    "630225000000": "循化撒拉族自治县",
    "632221000000": "门源回族自治县",
    "632222000000": "祁连县",
    "632223000000": "海晏县",
    "632224000000": "刚察县",
    "632321000000": "同仁县",
    "632322000000": "尖扎县",
    "632323000000": "泽库县",
    "632324000000": "河南蒙古族自治县",
    "632521000000": "共和县",
    "632522000000": "同德县",
    "632523000000": "贵德县",
    "632524000000": "兴海县",
    "632525000000": "贵南县",
    "632621000000": "玛沁县",
    "632622000000": "班玛县",
    "632623000000": "甘德县",
    "632624000000": "达日县",
    "632625000000": "久治县",
    "632626000000": "玛多县",
    "632701000000": "玉树市",
    "632722000000": "杂多县",
    "632723000000": "称多县",
    "632724000000": "治多县",
    "632725000000": "囊谦县",
    "632726000000": "曲麻莱县",
    "632801000000": "格尔木市",
    "632802000000": "德令哈市",
    "632803000000": "茫崖市",
    "632821000000": "乌兰县",
    "632822000000": "都兰县",
    "632823000000": "天峻县",
    "632857000000": "大柴旦行政委员会",
    "640104000000": "兴庆区",
    "640105000000": "西夏区",
    "640106000000": "金凤区",
    "640121000000": "永宁县",
    "640122000000": "贺兰县",
    "640181000000": "灵武市",
    "640202000000": "大武口区",
    "640205000000": "惠农区",
    "640221000000": "平罗县",
    "640302000000": "利通区",
    "640303000000": "红寺堡区",
    "640323000000": "盐池县",
    "640324000000": "同心县",
    "640381000000": "青铜峡市",
    "640402000000": "原州区",
    "640422000000": "西吉县",
    "640423000000": "隆德县",
    "640424000000": "泾源县",
    "640425000000": "彭阳县",
    "640502000000": "沙坡头区",
    "640521000000": "中宁县",
    "640522000000": "海原县",
    "650102000000": "天山区",
    "650103000000": "沙依巴克区",
    "650104000000": "新市区",
    "650105000000": "水磨沟区",
    "650106000000": "头屯河区",
    "650107000000": "达坂城区",
    "650109000000": "米东区",
    "650121000000": "乌鲁木齐县",
    "650202000000": "独山子区",
    "650203000000": "克拉玛依区",
    "650204000000": "白碱滩区",
    "650205000000": "乌尔禾区",
    "650402000000": "高昌区",
    "650421000000": "鄯善县",
    "650422000000": "托克逊县",
    "650502000000": "伊州区",
    "650521000000": "巴里坤哈萨克自治县",
    "650522000000": "伊吾县",
    "652301000000": "昌吉市",
    "652302000000": "阜康市",
    "652323000000": "呼图壁县",
    "652324000000": "玛纳斯县",
    "652325000000": "奇台县",
    "652327000000": "吉木萨尔县",
    "652328000000": "木垒哈萨克自治县",
    "652701000000": "博乐市",
    "652702000000": "阿拉山口市",
    "652722000000": "精河县",
    "652723000000": "温泉县",
    "652801000000": "库尔勒市",
    "652822000000": "轮台县",
    "652823000000": "尉犁县",
    "652824000000": "若羌县",
    "652825000000": "且末县",
    "652826000000": "焉耆回族自治县",
    "652827000000": "和静县",
    "652828000000": "和硕县",
    "652829000000": "博湖县",
    "652871000000": "库尔勒经济技术开发区",
    "652901000000": "阿克苏市",
    "652922000000": "温宿县",
    "652923000000": "库车县",
    "652924000000": "沙雅县",
    "652925000000": "新和县",
    "652926000000": "拜城县",
    "652927000000": "乌什县",
    "652928000000": "阿瓦提县",
    "652929000000": "柯坪县",
    "653001000000": "阿图什市",
    "653022000000": "阿克陶县",
    "653023000000": "阿合奇县",
    "653024000000": "乌恰县",
    "653101000000": "喀什市",
    "653121000000": "疏附县",
    "653122000000": "疏勒县",
    "653123000000": "英吉沙县",
    "653124000000": "泽普县",
    "653125000000": "莎车县",
    "653126000000": "叶城县",
    "653127000000": "麦盖提县",
    "653128000000": "岳普湖县",
    "653129000000": "伽师县",
    "653130000000": "巴楚县",
    "653131000000": "塔什库尔干塔吉克自治县",
    "653201000000": "和田市",
    "653221000000": "和田县",
    "653222000000": "墨玉县",
    "653223000000": "皮山县",
    "653224000000": "洛浦县",
    "653225000000": "策勒县",
    "653226000000": "于田县",
    "653227000000": "民丰县",
    "654002000000": "伊宁市",
    "654003000000": "奎屯市",
    "654004000000": "霍尔果斯市",
    "654021000000": "伊宁县",
    "654022000000": "察布查尔锡伯自治县",
    "654023000000": "霍城县",
    "654024000000": "巩留县",
    "654025000000": "新源县",
    "654026000000": "昭苏县",
    "654027000000": "特克斯县",
    "654028000000": "尼勒克县",
    "654201000000": "塔城市",
    "654202000000": "乌苏市",
    "654221000000": "额敏县",
    "654223000000": "沙湾县",
    "654224000000": "托里县",
    "654225000000": "裕民县",
    "654226000000": "和布克赛尔蒙古自治县",
    "654301000000": "阿勒泰市",
    "654321000000": "布尔津县",
    "654322000000": "富蕴县",
    "654323000000": "福海县",
    "654324000000": "哈巴河县",
    "654325000000": "青河县",
    "654326000000": "吉木乃县",
    "659001000000": "石河子市",
    "659002000000": "阿拉尔市",
    "659003000000": "图木舒克市",
    "659004000000": "五家渠市",
    "659006000000": "铁门关市",
    "652902000000": "库车市",
    "659005000000": "北屯市",
    "659007000000": "双河市",
    "659008000000": "可克达拉市",
    "659009000000": "昆玉市",
    "659010000000": "胡杨河市",
    "451082000000": "平果市",
    "130505000000": "任泽区",
    "130506000000": "南和区",
    "140703000000": "太谷区",
    "220184000000": "公主岭市",
    "340281000000": "无为市",
    "360113000000": "红谷滩区",
    "360783000000": "龙南市",
    "370614000000": "蓬莱区",
    "510118000000": "新津区",
    "530481000000": "澄江市"
  }
}